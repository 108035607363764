import React, { useState, useEffect } from 'react';
import Slider from 'react-slick';
import { Link } from 'react-router-dom';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { getGalleryInner } from '../Service/Api';
import LazyLoad from 'react-lazyload';
const HomeActivities = () => {
    const [data, setData] = useState([]); // State for gallery data
    const [loading, setLoading] = useState(true); // State for loading indicator

    useEffect(() => {
        const fetchData = async () => {
            try {
                const topperData1 = await getGalleryInner();
                const topperData = topperData1.filter((item) => item.category === 'Activities');
                console.log(topperData);

                // Sort the data by date in descending order and then slice to get the last 6 entries
                const getcurrentData = topperData
                    .sort((a, b) => new Date(b.date) - new Date(a.date))
                    .slice(0, 6)
                    .map((item) => ({
                        ...item,
                        date: formatDate(new Date(item.date)),
                    }));

                setData(getcurrentData);
            } catch (error) {
                console.error('Error fetching gallery data:', error);
                // Handle error (e.g., show error message)
            } finally {
                setLoading(false); // Set loading to false after data fetching is complete
            }
        };
        fetchData();
    }, []);

    const formatDate = (date) => {
        const day = (`0${date.getDate()}`).slice(-2);
        const monthNames = [
            'January', 'February', 'March', 'April', 'May', 'June',
            'July', 'August', 'September', 'October', 'November', 'December'
        ];
        const month = monthNames[date.getMonth()];
        const year = date.getFullYear();
        return `${day} ${month} ${year}`;
    };

    const emptyArray = [
        // Add any items you want to show when there's no data
    ];

    const settings = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 3,
        margin: 30,
        slidesToScroll: 1,
        initialSlide: 0,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true,
                },
            },
            {
                breakpoint: 1000,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true,
                },
            },
            {
                breakpoint: 700,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true,
                },
            },
        ],
    };

    return (
        <>
            <div className="row">
                <div className="slider-container">
                    <Slider {...settings}>
                        {data?.length > 0
                            ? data.filter((div) => div.category === 'Activities').map((item, index) => (
                                  <div className="item" key={index}>
                                      <div className="activitesbox">
                                          <div className="activitesboximg">
                                              <LazyLoad><img 
                                                  src={`https://webapi.entab.info/api/image/${item.attachments[0]}`}
                                                  className="img-fluid"
                                                  alt="Loreto Convent School Delhi Cantt"
                                              /></LazyLoad>
                                          </div>
                                          <div className="activitesboxdesc">
                                              <h6>{item.title}</h6>
                                              <p>{item.description}</p>
                                              <span>{item.date}</span> {/* Display the formatted date */}
                                          </div>
                                      </div>
                                  </div>
                              ))
                            : emptyArray.map((data, index) => (
                                  <div className="item" key={index}>
                                      <div className="activitesbox">
                                          <div className="activitesboximg">
                                              <LazyLoad><img 
                                                  src="https://webapi.entab.info/api/image/LCSD/public/Images/activites.jpg"
                                                  className="img-fluid"
                                                  alt="Loreto Convent School Delhi Cantt"
                                              /></LazyLoad>
                                          </div>
                                          <div className="activitesboxdesc">
                                              <h6>Poster making event for class II</h6>
                                              <p>
                                                  Poster making event for class II in which students
                                                  participated and present their creative posters
                                              </p>
                                              <span>12 March 2024</span>
                                          </div>
                                      </div>
                                  </div>
                              ))}
                    </Slider>
                </div>
            </div>
        </>
    );
};

export default HomeActivities;
