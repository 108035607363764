import React from 'react'
import LazyLoad from 'react-lazyload';
import { Link } from 'react-router-dom'
const JourneyLoreto = () => {
  return (
    <>
    <section className="page-header">
        <div className="page-header-bg"></div>
        <div className="page-header-shape-1"><LazyLoad><img src="https://webapi.entab.info/api/image/LCSD/public/Images/page-header-shape-1.png" alt="Loreto Convent School, Delhi Cantt"/></LazyLoad></div>
        <div className="container">
        <div className="page-header__inner">
            <ul className="thm-breadcrumb list-unstyled ml-0">
                <li><Link to="/"> Home</Link></li>
                <li> About Us </li>
                <li> Journey Loreto  </li>
            </ul>
            <h2>Journey Loreto </h2>
        </div>
        </div>
    </section>
    <section className="innerbody">
    <div className="container">
    <div className="col-md-12">
    <LazyLoad> <img src="https://webapi.entab.info/api/image/LCSD/public/Images/loreto_school.jpg" className="img-fluid mx-auto d-block imgspace" alt="Loreto Convent School, Delhi Cantt"/></LazyLoad>
    <p>MARY WARD (1585 - 1645) was the foundress of the institute of the Blessed Virgin Mary, a religious order which is now spread all over the world and which engages mainly in the education of girls. The 'Loreto' nuns are those who belong to the Irish branch of the institute. There are two other branches, commonly called the 'Roman branch' and the 'American branch'. The latter also uses the name 'Loreto' as it was originally founded in Ireland. Mary Ward was English and lived during a time of great persecution and hardship for those who remained true to the Catholic faith in England. As a young girl Mary wished to give herself to God as a nun, but all the convents in England had been closed down. She therefore sailed to Europe and became a novice in a poor Clare convent where her main life's work would be to pray on behalf of those who were in need of God 's special help. After some time, however, God revealed to her that her life was to be spent, not in prayer only, but in active work also. She gathered a group of other generous and spiritual young women, wives and mothers. In 1821 an Irish woman
    <br />
    called Mother Teresa Ball founded the first house of the Institute in Ireland and called it 'Loreto House'. From here in 1841 a group of nuns led by Mother Delphine Hart brought the Institute to India, and founded Loreto House, Calcutta. LORETO CONVENT SCHOOL, DELHI was founded in 1965.
    <br />
    We are deeply indebted to Mary Ward whose vision of education for girls was far in advance of her times. Recognising the significance of education for women and the important role they would play in society in the future, Mary Ward chose as her special area of service, the education of girls. We carry on that tradition to the present day and are continually nourished by her spiritual insights.</p>
    </div>   
    </div>
    </section>
   </>
  )
}

export default JourneyLoreto