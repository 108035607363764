import React from 'react'
import { Link } from 'react-router-dom'
import LazyLoad from 'react-lazyload';
const OurSchool = () => {
  return (
    <>
    <section className="page-header">
        <div className="page-header-bg"></div>
        <div className="page-header-shape-1"><LazyLoad><img src="https://webapi.entab.info/api/image/LCSD/public/Images/page-header-shape-1.png" alt="Loreto Convent School, Delhi Cantt"/></LazyLoad></div>
        <div className="container">
        <div className="page-header__inner">
            <ul className="thm-breadcrumb list-unstyled ml-0">
                <li><Link to="/"> Home</Link></li>
                <li> About Us </li>
                <li> Our School  </li>
            </ul>
            <h2>Our School </h2>
        </div>
        </div>
    </section>
    <section className="innerbody">
<div className="container">
<div className="row">
<div className="col-md-12">
<h1 className="head1">LORETO CONVENT, DELHI CANTT. FOUNDED : 1965</h1>
<p>Loreto Convent School was founded in 1965. It is an unaided minority Christian School owned, managed and conducted by The Loreto Educational Society (Loreto nuns) registered under the Societies Registration Act 1860. The school is meant primarily for Catholics and the children of Defence Personnel. Civilians are also admitted.
<br />
The aim of the school is to give its pupils a sound moral education while devoting special attention to their spiritual, intellectual, social and physical development. The school is affiliated to the Central Board of Secondary Education; (affiliation no. 2730135) and prepare girls for the Board’s examinations in Class X and XII.
<br />
Loreto Convent, Delhi is part of an international educational organization with branches in many different countries on every continent. Our educational institutions are open to girls of all religions, and we have a special concern for Catholics. We are strongly committed to the cause of justice, therefore we offer educational opportunities also to the weaker section of society.
<br />
We see each girl as a unique and precious gift to her family and society, a person created by God to know and love Him and to work for Him by making this world a better place to live in.</p>
<p><b>Parents are the first and most important educators to their children. School education can only be built on the foundation laid by the family.</b></p>
<h1 className="head1">SPECIAL EFFORT ARE MADE AT LORETO</h1>
<ul className="lilist">
<li>To help students to become mature, reliable people of character.</li>
<li>To be clear and firm on principles and courageous in action.</li>
<li>To value and use their freedom judiciously.</li>
<li>Strive after excellence in every field.</li>
<li>To be unselfish in the service of their fellow human beings and appreciate all religions.</li>
</ul>
</div>
</div>
</div>
</section>
   </>
  )
}

export default OurSchool
