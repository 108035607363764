import React from 'react'
import { Link } from 'react-router-dom'
import LazyLoad from 'react-lazyload';
const AanchalSpecialSchool = () => {
  return (
    <>
    <section className="page-header">
        <div className="page-header-bg"></div>
        <div className="page-header-shape-1"><LazyLoad><img src="https://webapi.entab.info/api/image/LCSD/public/Images/page-header-shape-1.png" alt="Loreto Convent School, Delhi Cantt"/></LazyLoad></div>
        <div className="container">
        <div className="page-header__inner">
            <ul className="thm-breadcrumb list-unstyled ml-0">
                <li><Link to="/"> Home</Link></li>
                <li> Outreach </li>
                <li> Aanchal Special School Chanakyapuri </li>
            </ul>
            <h2>Aanchal Special School Chanakyapuri </h2>
        </div>
        </div>
    </section>
    <section className="innerbody">
   <div className="container contact-page">
<div className="row">
      <div className="col-md-12">
      <h1 className="head1">Report on Outreach Program</h1>
      <p>On 9th August 2024, the students of class 8A, along with their class teacher, Mrs. Ruchi Goel, participated in an outreach program at Aanchal Special School in Chanakyapuri. Upon arrival, the institute's in-charge gave a tour of the classes and the vocational center, showcasing the beautiful items such as paper bags and folders made by the special children. The students distributed stationery items like notebooks, pens, pouches, and sweets to each class, which brought immense joy to the recipients.</p>
      <p>Following the distribution, everyone gathered for a short cultural program performed by the students, which was well-received and prompted the special children to join in the dancing. This interaction was both a joyful and emotional experience, teaching the students the importance of being considerate towards differently-abled children and fostering a lasting connection with them.</p>
      </div>
      </div>
<div className="row align-images g-4">
<div className="col-xl-3 col-lg-4 col-md-6 col-12">
<LazyLoad><img src="https://webapi.entab.info/api/image/LCSD/public/Images/aanchal-1.jpg"  className="img-fluid" alt="Loreto Convent School, Delhi Cantt"/></LazyLoad>
</div>
<div className="col-xl-3 col-lg-4 col-md-6 col-12">
<LazyLoad><img src="https://webapi.entab.info/api/image/LCSD/public/Images/aanchal-2.jpg"  className="img-fluid" alt="Loreto Convent School, Delhi Cantt"/></LazyLoad>
</div>
<div className="col-xl-3 col-lg-4 col-md-6 col-12">
<LazyLoad><img src="https://webapi.entab.info/api/image/LCSD/public/Images/aanchal-3.jpg"  className="img-fluid" alt="Loreto Convent School, Delhi Cantt"/></LazyLoad>
</div>

<div className="col-xl-3 col-lg-4 col-md-6 col-12">
<LazyLoad><img src="https://webapi.entab.info/api/image/LCSD/public/Images/aanchal-4.jpg"  className="img-fluid" alt="Loreto Convent School, Delhi Cantt"/></LazyLoad>
</div>

<div className="col-xl-3 col-lg-4 col-md-6 col-12">
<LazyLoad><img src="https://webapi.entab.info/api/image/LCSD/public/Images/aanchal-5.jpg"  className="img-fluid" alt="Loreto Convent School, Delhi Cantt"/></LazyLoad>
</div>
<div className="col-xl-3 col-lg-4 col-md-6 col-12">
<LazyLoad><img src="https://webapi.entab.info/api/image/LCSD/public/Images/aanchal-6.jpg"  className="img-fluid" alt="Loreto Convent School, Delhi Cantt"/></LazyLoad>
</div>
<div className="col-xl-3 col-lg-4 col-md-6 col-12">
<LazyLoad><img src="https://webapi.entab.info/api/image/LCSD/public/Images/aanchal-7.jpg"  className="img-fluid" alt="Loreto Convent School, Delhi Cantt"/></LazyLoad>
</div>

</div>

</div>
</section>
   </>
  )
}

export default AanchalSpecialSchool