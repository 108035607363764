import React from 'react'
import { Link } from 'react-router-dom'
import LazyLoad from 'react-lazyload';
const OurSpirituality = () => {
  return (
    <>
    <section className="page-header">
        <div className="page-header-bg"></div>
        <div className="page-header-shape-1"><LazyLoad><img src="https://webapi.entab.info/api/image/LCSD/public/Images/page-header-shape-1.png" alt="Loreto Convent School, Delhi Cantt"/></LazyLoad></div>
        <div className="container">
        <div className="page-header__inner">
            <ul className="thm-breadcrumb list-unstyled ml-0">
                <li><Link to="/"> Home</Link></li>
                <li> Founder </li>
                <li> Our Spirituality  </li>
            </ul>
            <h2>Our Spirituality </h2>
        </div>
        </div>
    </section>
    <section className="innerbody">
<div className="container">

<div className="col-md-12">
<h1 className="head1">ROOTED IN THE TRIUNE GOD:</h1>
<p><LazyLoad><img src="https://webapi.entab.info/api/image/LCSD/public/Images/Rooted-Triun-God.png"  className="img-fluid left-align-images" alt="Loreto Convent School, Delhi Cantt"/></LazyLoad> From the days of her childhood, Mary Ward seemed to have been rooted in God; for her God was always, 'the beginning, middle and end of all our good'. In other words Mary Ward was at home in the Blessed Trinity; her whole life tended to the Father whose glory was once revealed to her as the ultimate end of her life and work. Her desire to follow Jesus is revealed in her own words 'To proceed as Christ did was the portion of grace I only desired for my part and all the happiness I wished for in this life’. She attributed to the Spirit of God that ‘zeal for souls out of love for the Divine glory’ which burnt in her heart and in those of her companions.</p>
<p>A member, rooted in God, seeks God in all things through humble prayer and personal effort. Her desire is to be responsive to the leading of the Spirit in all things. Her members are expected to be ‘contemplatives in action’ which means being recollected and absorbed in God in the midst of the crowd. This requires of them to be women of prayer- rooted in the Trinity. Caught up in the tangled activity of daily life and treasuring all the details of the time and place, they find, taste, smell and touch a loving God who wonderfully labours in the world.</p>
</div>

<div className="col-md-12">
<h1 className="head1">EUCHARIST CENTERED:</h1>
<p><LazyLoad><img src="https://webapi.entab.info/api/image/LCSD/public/Images/Eucharist-Centred.png"  className="img-fluid right-align-images" alt="Loreto Convent School, Delhi Cantt"/></LazyLoad>
Mary Ward drew inspiration and strength from the Blessed Sacrament and she wanted that her companions should at all times have recourse to this open access to God. The same Christ who called us for mission unites us at the table of the Eucharist. It is in community and personal adoration that the members experience unity since the chief bond to cement the union of members among themselves and with their head, is the love of God, Our Lord. “Let us endeavour to make frequent visits to adore and speak with Him as an expression of our faith… such visits will help us to live ever more deeply in Christ.”</p>
<p>The Glory of God: Service of our neighbour: The fundamental vision with which Mary Ward set forth to begin her Institute was the ‘Gloria Vision’. Even before the Congregation took concrete form it was given its distinctive mark. Our work is to be for the glory of God. In this call from God we can hear the echo of Mary’s apostolic vision. The notion of glory refers to that power, the majesty, honour and radiance which belong to God alone. It is the manifestation of the saving power of God. She would labour for the glory of God and the one aim of her life was to be the praise and glory of God in everything, hence she was committed to her apostolic vision of helping others. The emphasis on ‘service of neighbour', ‘helping souls’ came from a profound conviction that grew with her in her personal faith history. She was influenced by the Christ of the Spiritual Exercises- Christ in action, who went from place to place to proclaim the saving power of God.
</p>
</div>

<div className="col-md-12">
<h1 className="head1">TOTAL AVAILABILITY :</h1>
<p>“Our Institute should be marked by the asceticism of ‘availability’. The basic motivation behind this availability is love and a pure intention of serving God, sincere ‘zeal for souls’ … we are here for others, the heart of our mission is service of mankind”. This was the message of the General Superior to the whole Institute in 1980. Mary’s response was an unconditional availability of faith and hope, a total surrender of her person to the will of God- Mary Ward was totally available in her time.</p>
<ul className="lilist">
<li>Available to the Church;</li>
<li>Available for young girls;</li>
<li>Available to her companions;</li>
<li>Available with her pain and sickness;</li>
<li>Available to the Catholics, to the women, to the poor, to the sick…</li>
</ul>
<p>In the words of Pope John Paul II, “Like your Foundress, Mary Ward, you are called to exemplify that courage and generosity which are components of true fidelity- the courage to face the sacrifices necessary to uphold her religious charism, which has become your own… and the generosity to pursue this perseveringly under the Banner of the Cross of Christ, together with His Mother Mary.”

</p>
</div>
</div>
</section>
   </>
  )
}

export default OurSpirituality
