import React from 'react'
import { Link } from 'react-router-dom'
import LazyLoad from 'react-lazyload';
const SchoolReport = () => {
  return (
    <>
    <section className="page-header">
        <div className="page-header-bg"></div>
        <div className="page-header-shape-1"><LazyLoad><img src="https://webapi.entab.info/api/image/LCSD/public/Images/page-header-shape-1.png" alt="Loreto Convent School, Delhi Cantt"/></LazyLoad></div>
        <div className="container">
        <div className="page-header__inner">
            <ul className="thm-breadcrumb list-unstyled ml-0">
                <li><Link to="/"> Home</Link></li>
                <li> Prospectus </li>
                <li> School Report  </li>
            </ul>
            <h2>School Report </h2>
        </div>
        </div>
    </section>
    <section className="innerbody">
<div className="container">
<div className="row">
<div className="col-md-12">
<div className="table-responsive">
<table className="table table-striped table-bordered align-middle">

<tbody><tr>
                 <th colspan="3" style={{textAlign: "center"}}>

                     <strong>ADMISSION AND WITHDRAWAL PROCEDURE </strong>

                 </th>
             </tr>
             <tr>
             <td colspan="3"><b>ACADEMICS</b> </td>
                            
             </tr>


             <tr>
                 <td colspan="3">The Results of the CBSE Board examination   for the batch of 2018-19 </td>
                
             </tr>
             <tr>
                 <td colspan="2"><b>Class X</b></td>
                 <td rowspan="8">Highest marks: 96.6% </td>
               
             </tr>
             <tr>
                 <td>No. Of Candidates Enrolled </td>
                 <td> 112 </td>
                 
             </tr>

            
            <tr>
                <td>No. Of Candidates Appeared</td>
                <td>111</td>
            </tr>

           <tr>
              <td>No. Of Candidates Passed    </td>
               <td>110</td>

           </tr>
           <tr>
               <td>No. Of Candidates failed </td>
               <td>0</td>
           </tr>
           <tr>
               <td>No. Of Candidates taken TC     </td>
               <td>01</td>
           </tr>
           <tr>
               <td>No. Of compartments</td>
               <td>01</td>
           </tr>
           <tr>
               <td colspan="2"><b>Class XII</b></td>
               
                 
                
           </tr>
           <tr>
               <td>No. Of Candidates Enrolled </td>
               <td>102</td>
                <td rowspan="6">Highest Marks: 97.2 %</td>
           
           </tr>
           <tr>
               <td> No. Of Candidates Appeared </td>
               <td>102</td>
           </tr>

           <tr>
               <td>No. Of Candidates Passed  </td>
               <td>101</td>
           </tr>
           <tr>
               <td>No. Of Candidates failed </td>
               <td>0</td>
           </tr>
           <tr>
               <td>No. Of compartments </td>
               <td>01</td>
           </tr>
           <tr>
           <td colspan="2">(The result sheets are attached for reference)</td>
               </tr>
           <tr>
               <td colspan="3">
                  <b><u>SPECIAL ASSEMBLIES</u></b><br/>
                   <ul className="lilist">
                       <li><b>Investiture Ceremony:</b> The investiture Ceremony for the newly elected student council was held on 07 July 2018..</li>
                       <li>YCS conducted summer holiday assembly on 18 May 2018.</li>
                       <li>A farewell assembly was conducted for the outgoing batch of 2018-19 on 01 February 2019 by the students and teachers of Class XI</li>
                       <li>A special assembly  was organized on the 28th of February 2019, to bid farewell to our very special teacher Mrs Mandira Ghoshal, who has been the school’s Hindi music teacher for the last 24 years.</li>
                       <li>The students of class 4 and class 5, presented a beautiful assembly on 'Unity in Diversity' on the eve of Gandhi Jayanti.</li>
                   </ul>
                   <br/>


          
                    <b><u>FAITH FORMATION</u></b><br/>
                      <ul className="lilist">
                       <li>Mass of the Holy Spirit was organized in the beginning of the Academic session.</li>
                      <li>On 20 April 2018 students participated in dace competition organized by Viswas Kiran Catechetical Center.</li>
                      <li>On 26 April 2018, three students participated in the inter school Bible Quiz Competition.</li>
                      <li>	On 01 May 2018 Crowning of our lady Prayer Service was organized.</li>
                      <li>	School Blessing was done on 01 May 2018 by Fr. Arnold.</li>
                      <li>On 03 August the feast of St Maria John Vianney was celebrated along with the First Friday mass.</li>
                      <li>Our School students secured second position at the Don Bosco Bible quiz conducted on 19 August 2018.</li>
                      <li>On 31 August eight students along with Ms. Francis attended a leadership programme AAHVAAN at diocesan level. </li>
                      <li>	Advent wreath prayer services are conducted to prepare for the coming of the Lord.</li>
                      <li>Christmas Programme was organised on 15 December 2015wherein the Junior school students had  put up the nativity play.</li>
                      <li>The students of our school shared Christmas spirit with the Jeevan Rekha children when they hosted a programme for them, who in turn, presented a tableau on Nativity. This was followed by lunch and each child received an attractive hamper.</li>
                      <li>The spirit of Christmas was also celebrated with the staff on 21 December 2018.</li>
                      <li>The Christmas cheer was also shared by the members of the Support Staff. </li>
                   </ul>
                   <br/>
                   <b><u>CO- CURRICULAR  ACTIVITIES</u></b><br/>
                      <ul className="lilist">
                         <li><b>International Yoga Day :</b> International day of Yoga was celebrated in school on 21st June 2018</li>
                         <li><b>Peace March:</b> On 19th April 2018, Thursday, under JPIC initiative students of classes 11th and 12th participated in a silent march to express solidarity for the cause of upholding human rights of women and children.</li>
                         <li><b>Support Staff Day :</b> On the occasion of International Labour Day, the LTS club organized the celebrations of SUPPORT STAFF DAY on 01 May, 2018 in honour of the service of all the members of the domestic staff</li>
                         <li><b>LTS Meet:</b> An Inter-school LTS meet was held in Loreto Convent School on 06th May 2018 to initiate and encourage the LTS movement in Army Schools with the participation of students from Loreto.</li>
                         <li><b>Intra LC MUN:</b> It was conducted in the school on 24 and 25 July 2018 for students from classes 8 to 12.</li>
                         <li><b>Independence Day Celebrations -</b> On the occasion of the 72nd Independence Day of our nation, a commemorative prayer service was held on 14 August 2019 followed by a pageant of patriotism.</li>
                         <li><b>Teacher’s Day -</b> On September 5, 2018, Teacher’s Day programme was organised by the students in the Mary Ward Auditorium.</li>
                         <li>A march to spread the awareness of saying no to crackers was organised in November by JPIC.</li>
                         <li><b>Tree Plantation:</b> About 2000 neem trees were planted in Noida in collaboration with My Tree NGO and Noida Development Authority on August 15, 2018. 1000 saplings were also planted at Amity University campus Gurugram on August 28, 2018 as part of the plantation drive by JPIC.</li>
                         <li><b>Mary Ward Fest :</b> On 20th September 2018, 6 students of class XI presented a street play based on Social and moral values at Mary Ward Fest organised by Nirmala Convent Girls’ Inter College in Jhansi.</li>
                         <li><b>An ART EXHIBITION</b> by our budding artists of classes 5-8 was organized on October 6, 2018.  Their art work was on display in the school quadrangle on PTM day. </li>
                         <li><b>Scrap out of Scrap :</b> On 22 January 2019, twelve students from classes VIII and IX attended the launch workshop of ‘Scrap out Scrap’ SOS PROJECT, initiated by TERI The Energy and Resources Institute:</li>
                         <li>Loreto day was celebrated with special programmes for staff and students.</li>
                         <li>One Billion Rising :On 14 February 2019 , students participated in the One Billion Rising movement in school to recognize the efforts of the activists fighting against the oppression of women.  </li>
                         </ul>
               <br/>
                   <b><u>JPIC NGM</u></b><br/>
                        <ul className="lilist">
                       <li>THE 5TH National General Meeting of JPIC was hosted at Loreto Convent, Delhi from 11 - 13 February 2019</li>
                   </ul>
                   <br/>
                    
                   <b><u>ANNUAL CARNIVAL</u></b><br/>
                         <ul className="lilist">
                       <li><b>Annual Carnival :</b> Annual Fete was organised on 01 Dec 2018 in the school premises. Stalls of creative work made during the SUPW periods like wall decorations, paper bags, napkins, decoration pieces etc. handcrafted by the students were put up along with the exciting game stalls that drew large crowds</li>
                   </ul>
                   <br/>
                     <b><u>GAMES</u></b><br/>
                       <ul className="lilist">
                       <li><b>Athletics:</b> Deepali Ganndotra of class 12 and Bhoomika of class 8 secured the first position in 200, 400 and 600 meters race in under 17 and under 14 categories .in the Zonal athletic meet on 14th and 15th September at Thyagraj stadium.</li>
                          <li><b>Sports Day–</b> The school Sports Day was held on 17 November 2018 at Harbaksh Stadium, The theme for the mega event was ‘Preserve Creation for the future generation’. All Students from Prep to 12 participated in the mega event.</li>
                   </ul>
                   <br/>

                    <b><u>ANNUAL CARNIVAL</u></b><br/>
                        <ul className="lilist">
                       <li><b>Annual Carnival :</b> Annual Fete was organised on 01 Dec 2018 in the school premises. Stalls of creative work made during the SUPW periods like wall decorations, paper bags, napkins, decoration pieces etc. handcrafted by the students were put up along with the exciting game stalls that drew large crowds</li>
                   </ul>
                   <br/>
                     <b><u>DISASTER MANAGEMENT</u></b><br/>
                       <ul className="lilist">
                       <li>A Training programme on Disaster management was conducted by the District Disaster Management Authority On July 27, 2018 in the school premises for students and staff members.</li>

                   </ul>
                  
                   <br/>
                     <b><u>SOCIAL COMMITMENT </u></b><br/>
                 <ul className="lilist">
                       <li><b>KERALA RELIEF -</b> Students and teachers came forward to contribute materials such as Rice, Atta, biscuits, pulses, toiletries, clothes and shoes for the Kerala Flood relief. On September 12, 2018, the collected material was handed over to the District magistrate and Kerala house authorities.</li>
                   </ul>
                   <br/>
                    <b><u>OUTREACH PROGRAMME </u></b><br/>
                       <ul className="lilist">
                       <li>The students from class VI to XII are engaged in an ongoing outreach programme, called Jeevan Rekha for the underprivileged students of the nearby village Jharera. Students take turn to stay back after school for two and a half hours to assist them in their studiesand take part in this each one teach one programme.  The students are also provided with a meal. </li>
                          <li>On 20 December 2018, the students of class VIII and X hosted a Christmas programme for them. Each child was gifted with a hamper. </li>
                          <li>It is a conscious endeavor at Loreto to instill in each of its students gratitude for the life they have and live upto the school motto “Receive that you may give.</li>
                   </ul>
                   <br/>
                   <b><u>ECO FRIENDLINESS </u></b><br/>
                         <ul className="lilist">
                       <li>In pursuit of making the school environment friendly solar panels and rain water harvesting equipment has been successfully installed.</li>
                          
                   </ul>
                   <br/>
                    <b><u>MOTHER GENERAL’S VISIT</u></b><br/>
                         <ul className="lilist">
                       <li>The Institute leader, Sr. Noella Corscadden, Vicar General Sr. Igora Pinto and General consultor Sr. Judith Nekesa visited the school on  23rd October.</li>
                          <li>Three more General consultors Sr. Macarena, Sr. Brenda Egan and Sr. Teresa Mcglinchey visited our school on October 29, 2018.</li>
                          
                   </ul>
                   <br/>
                   <b><u>WORKSHOPS FOR STUDENTS</u></b><br/>
                        <ul className="lilist">
                       <li>The school organized a workshop on oral hygiene, good and bad touch for students of Junior school.</li>
                          <li>On 25 July 2019, Vedic Math’s, Science is fun and scrabble workshop for developing vocabulary was conducted for students of middle school. </li>
                          <li>An educational trip was organized for students of classes 10 and 12 to Thayagaraj stadium on 03 November 2018 to attend a youth movement called ‘CLIMATE JAMBOREE’, organized by TERI university, on climate and sustainability.</li>
                          <li>The school counsellor conducted workshops for students of senior school to help them to cope up with the stress.</li>
                          <li>A Career Councelling work shop was conducted on 31 Jan 2018.</li>
                          
                   </ul>
                   <br/>
                   <b><u>WORKSHOP FOR TEACHERS</u></b><br/>
             <ul className="lilist">
                       <li>Sr. Archana conducted a day long orientation programme for teachers on 27 March 2018.</li>
                          <li>On 24 March 2018 Ms. Bhatti, Ms. Mamgain and Ms. Mathew attended a workshop conducted by CUAC. </li>
                          <li>	Ms. Rekha Jacob attended a CBSE capacity building programme on 24 and 25 April 2018.</li>
                          <li>Ms. Parameswaran and Mr. Ajay attended a Physics workshop on 28 April 2018.</li>
                          <li>	Ms. Mamgain attended a CBSE capacity building programme on 13 and 14 August 2018.</li>
                          <li>Ms. N Davis attended a CBSE capacity building programme on History</li>
                          <li>	On 16 August 2018 Ms. Simi Joseph, Ms. Domingo attended a Catechism workshop at Yusuf Sadan.</li>
                          <li>The teachers attended various capacity building workshops for Science, Social Science, English Mathematics and Psychology conducted by CBSE.</li>
                          <li>In keeping with the need to function as counselors, the teachers attended a workshop by Dr. V. S. Ravindran is a renowned educational psychologist and trainer. </li>
                          <li>A workshop on POCSO Act  and child protection policies was also attended by all teachers </li>
                          <li>Teacher Sensitivity workshop was conducted by the school counselor. </li>
                          <li>	On 8 August 2018 Ms. Juby Chandran and Ms. Dennis attended an interaction programme with the renowned author and Poet Ruskin Bond.</li>
                          <li>	Ms. Andrews attended a CBSE Social studies capacity building programme on 20 and 21 August 2018.</li>
                          <li>	Ms. V Grover attended a Children’s Publishing Enclave on 25 August 2018.</li>
                          <li>Ms. Risa Joseph    attended a CBSE capacity building programme on Psychology on 12 and 13 October 2018.</li>
                          <li>Ms. Priya Nasir, Ms. Chandani, Ms. S Kancharla and Ms. Dadwal attended a work shop by Dr. V S Raveendran on counselling on 13 Oct 2018.</li>
                          <li>Ms. Aggarwal attended a CBSE  Physical Education capacity building programme on 07 and 8 Dec 2018.</li>
                          <li>	Maths work shop was attended by Ms. Bhatti, Ms. Babu, Ms. Prassd and Ms. Dadwal on 12 Jan 2019.</li>
                          <li>Ms. Meherotra and Sr. Rajni attended a Hindi work shop on 12 Jan 2019.</li>
                          <li>Ms. Raizada attended a work shop on mordenisation in education on 30 Jan 2019. </li>
                          <li>Ms. G Aggarwal and Ms. Mathew attended a workshop by ICTRC on 20 .</li>
                          <li>Ms. Jamesina and 2 students attended the LTS diamond jubilee celebration at Kolkota on 10 Feb 2019.</li>
                          <li>Ms. Stella and Ms. Deanne attended a seminar on Self care for Care Givers on 01-02 Sep 2018.</li>
                          <li>Sr. Phyllys Morris visited Loreto Delhi on 28 Sep 2018 and interacted with both Christian students and teachers.</li>
                          <li>Annual retreat was organized for Christian students of classes 9-12 on 29 &amp; 30 September.</li>
                          <li>On 12 October the Christian stidents of Classes 5 to 8 participated in the Bible quiz condected by the diocese. Many student secured high positions. </li>
                          <li><b>Orientation for Parents:</b> An interactive session ÍNTERFACE’was organized for the parents and students by the Sr. School Faculty members on 31 Jan 2019.</li>
                          
                   </ul>
                   <br/>
                
                   <b><u>Alumnae</u></b><br/>
                        <ul className="lilist">
                       <li><b>Alumnae Elections:</b> An inclusive Loreto Alumnae Re-Union was organised in the school premises on 15 December 2018. A new council was elected. The new office bearers elected on the day were Mrs. Vandana Grover (President, alumna Loreto Delhi), Mrs. Sharmila Sinha(Vice-President, alumna Loreto Lucknow ), Mrs. Sherin Andrews(Secretary, alumna Loreto House) and Mrs. Rupinder Oberoi(Treasurer, alumna Loreto Delhi). </li>
                          <li><b>Internationa; Women’s day:</b> Balance for better was the theme for International Women’s Day held on 8 March 2019. It was organised by the Loreto Alumnae association. </li>
                          
                          
                   </ul>
               </td>
           </tr>
         </tbody>

</table>
</div>
</div>
</div>
</div>
</section>
   </>
  )
}

export default SchoolReport