import React from 'react'
import { Link } from 'react-router-dom'
import LazyLoad from 'react-lazyload';
function PTA() {
    return (
        <>
            <section className="page-header">
                <div className="page-header-bg"></div>
                <div className="page-header-shape-1"><LazyLoad><img src="https://webapi.entab.info/api/image/LCSD/public/Images/page-header-shape-1.png" alt="Loreto Convent School, Delhi Cantt"/></LazyLoad></div>
                <div className="container">
                    <div className="page-header__inner">
                        <ul className="thm-breadcrumb list-unstyled ml-0">
                            <li><Link to="/"> Home</Link></li>
                            <li> Office Bearers </li>
                            <li> PTA</li>
                        </ul>
                        <h2>PTA </h2>
                    </div>
                </div>
            </section>
            <section className="innerbody">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="iframe-container" style={{ position: 'relative', overflow: 'hidden', height: '100%' }}>
                                <iframe
                                    src='https://webapi.entab.info/api/image/LCSD/public/pdf/pta.pdf'
                                    title="PTA Document"
                                    width="100%"
                                    height="600px"
                                    style={{ border: 'none' }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>

    )
}

export default PTA