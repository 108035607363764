import React from 'react'
import { Link } from 'react-router-dom'
import LazyLoad from 'react-lazyload';
const MissionStatement = () => {
  return (
    <>
    <section className="page-header">
        <div className="page-header-bg"></div>
        <div className="page-header-shape-1"><LazyLoad><img src="https://webapi.entab.info/api/image/LCSD/public/Images/page-header-shape-1.png" alt="Loreto Convent School, Delhi Cantt"/></LazyLoad></div>
        <div className="container">
        <div className="page-header__inner">
            <ul className="thm-breadcrumb list-unstyled ml-0">
                <li><Link to="/"> Home</Link></li>
                <li> Founder </li>
                <li> Vision Statement  </li>
            </ul>
            <h2>Vision Statement </h2>
        </div>
        </div>
    </section>
    <section className="innerbody">
   <div className="container">
      <div className="row">
         <div className="col-xl-6">
         <div className="innerbody_left__img">
         <LazyLoad>   <img src="https://webapi.entab.info/api/image/LCSD/public/Images/vission-1.jpg" alt="Loreto Convent School, Delhi Cantt" className="img-fluid"/></LazyLoad>
                  </div>
         </div>
         <div className="col-xl-6">
            <div className="innerbody__right">
            <h1 className="head1">Vision Statement</h1>
            <p><b>VISION FOR LORETO EDUCATION IN SOUTH ASIA PROVINCE</b></p>
               <p>We empower Loreto students to discover their full potential and uphold the values of humanity as global citizens.</p>
            </div>
         </div>
      </div>

 
   </div>
</section>
   </>
  )
}

export default MissionStatement