import React from 'react'
import { Link } from 'react-router-dom'
import LazyLoad from 'react-lazyload';
const PanLoretoCelebration = () => {
  return (
    <>
    <section className="page-header">
        <div className="page-header-bg"></div>
        <div className="page-header-shape-1"><LazyLoad><img src="https://webapi.entab.info/api/image/LCSD/public/Images/page-header-shape-1.png" alt="Loreto Convent School, Delhi Cantt"/></LazyLoad></div>
        <div className="container">
        <div className="page-header__inner">
            <ul className="thm-breadcrumb list-unstyled ml-0">
                <li><Link to="/"> Home</Link></li>
                <li> Pan-Loreto Celebration  </li>
            </ul>
            <h2>Pan-Loreto Celebration </h2>
        </div>
        </div>
    </section>
    <section className="innerbody">
   <div className="container">
      <div className="row">
         <div className="col-md-12">
            <div className="accordion achievementacc" id="accordionExample">
                
               <div class="accordion-item">
                  <div className="card">
                  <div className="card-header">
                     <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                     PAN LORETO ALUMNAE CELEBRATIONS " GRAND BALL" - 28TH DECEMBER 2017
                     </button>
                  </div>
                  <div id="collapseOne" className="collapse show" data-bs-parent="#accordionExample">
                     <div className="card-body">
                     <LazyLoad> <img src="https://webapi.entab.info/api/image/LCSD/public/Images/Grand-Ball.jpg"  className="img-fluid mx-auto d-block" alt="Loreto Convent School, Delhi Cantt"/></LazyLoad>
                     </div>
                  </div>
               </div>
               </div>
               <div class="accordion-item">
                  <div className="card">
                  <div className="card-header">
                     <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                     PAN LORETO ALUMNAE CELEBRATIONS " RIVER CRUISE" - 27TH DECEMBER 2017
                     </button>
                  </div>
                  <div id="collapseTwo" className="collapse" data-bs-parent="#accordionExample">
                     <div className="card-body">
                     <LazyLoad>   <img src="https://webapi.entab.info/api/image/LCSD/public/Images/River-Cruise.jpg" className="img-fluid mx-auto d-block" alt="Loreto Convent School, Delhi Cantt"/></LazyLoad>
                     </div>
                  </div>
               </div>
               </div>
               <div class="accordion-item">
                  <div className="card">
                  <div className="card-header">
                     <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                     PAN LORETO ALUMNAE CELEBRATIONS " SYMPOSIUM" - 18TH NOVEMBER 2017
                     </button>
                  </div>
                  <div id="collapseThree" className="collapse" data-bs-parent="#accordionExample">
                     <div className="card-body">
                     <LazyLoad>  <img src="https://webapi.entab.info/api/image/LCSD/public/Images/Symposium.jpg"  className="img-fluid mx-auto d-block" alt="Loreto Convent School, Delhi Cantt"/></LazyLoad>
                     </div>
                  </div>
               </div>
            </div>
            </div>
         </div>
      </div>
   </div>
</section>
   </>
  )
}

export default PanLoretoCelebration