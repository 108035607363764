import React from 'react'
import { Link } from 'react-router-dom'
import LazyLoad from 'react-lazyload';
const OfficeHours = () => {
  return (
    <>
    <section className="page-header">
        <div className="page-header-bg"></div>
        <div className="page-header-shape-1"><LazyLoad><img src="https://webapi.entab.info/api/image/LCSD/public/Images/page-header-shape-1.png" alt="Loreto Convent School, Delhi Cantt"/></LazyLoad></div>
        <div className="container">
        <div className="page-header__inner">
            <ul className="thm-breadcrumb list-unstyled ml-0">
                <li><Link to="/"> Home</Link></li>
                <li> Prospectus </li>
                <li> Office Hours  </li>
            </ul>
            <h2>Office Hours </h2>
        </div>
        </div>
    </section>
    <section className="innerbody">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">

                        <table className="table table-striped table-bordered">
                            <tbody>


                                <tr>
                                    <td>Office Timings</td>
                                    <td>8:00 a.m - 11.30 a.m </td>
                                </tr>

                                <tr>
                                    <td>Principal</td>
                                    <td>9:00 a.m - 01:00 p.m (Monday to Friday)<br />
                                        Please take prior appointments.</td>
                                </tr>

                                <tr>
                                    <td>School Telephone</td>
                                    <td><Link to="tel:011-25692299">011-25692299</Link></td>
                                </tr>

                                <tr>
                                    <td>School E-mail</td>
                                    <td><Link to="mailto: loretodelhi@gmail.com">loretodelhi@gmail.com</Link></td>
                                </tr>

                            </tbody>
                        </table>

                        <br />
                    </div>
                </div>
            </div>
        </section>
   </>
  )
}

export default OfficeHours