
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom'
import LazyLoad from 'react-lazyload';
const PaintedLife = () => {
    useEffect(() => {
        if (typeof window.jQuery === 'undefined') {
            console.error('jQuery is not loaded');
            return;
        }

        if (typeof window.jQuery.fn.magnificPopup === 'undefined') {
            console.error('Magnific Popup plugin is not loaded');
            return;
        }
        window.jQuery('.zoom-gallery').magnificPopup({
            delegate: 'a',
            type: 'image',
            closeOnContentClick: false,
            closeBtnInside: false,
            mainClass: 'mfp-with-zoom mfp-img-mobile',
            image: {
                verticalFit: true,
                titleSrc: function (item) {
                    return item.el.attr('title') + ' &middot; <a class="image-source-link" href="' + item.el.attr('data-source') + '" target="_blank"></a>';
                }
            },
            gallery: {
                enabled: true
            },
            zoom: {
                enabled: true,
                duration: 300,
                opener: function (element) {
                    return element.find('img');
                }
            }
        });
    }, []);
  return (
    <>
    <section className="page-header">
        <div className="page-header-bg"></div>
        <div className="page-header-shape-1"><LazyLoad><img src="https://webapi.entab.info/api/image/LCSD/public/Images/page-header-shape-1.png" alt="Loreto Convent School, Delhi Cantt"/></LazyLoad></div>
        <div className="container">
        <div className="page-header__inner">
            <ul className="thm-breadcrumb list-unstyled ml-0">
                <li><Link to="/"> Home</Link></li>
                <li> Founder </li>
                <li> Painted Life  </li>
            </ul>
            <h2>PaintedLife </h2>
        </div>
        </div>
    </section>
    <section className="innerbody">
<div className="container">
   <div className="row">


    <div className="col-md-12">
        <p>There are fifty paintings, each 142 x 105 cm, known as the <b>‘Painted Life of Mary Ward’</b> that show her spiritual journey. They are displayed in the <b>‘Mary Ward Hall’</b> in Augsburg in Germany. Very little information has come down to us as to the origins of the paintings. It is most probable that they were painted by various artists somewhere between Flanders and the Tyrol in the second half of the seventeenth century. There is written evidence that places them in Munich between <b> 1680 and 1717</b>, but how they came to Augsburg is unknown. </p> 

	<p>The initiative to commission the paintings must have come from Mary Ward’s first companions as the paintings tell the story of her life in considerable detail. Writing her life would have been risky as <b>Mary Ward’s</b> institute had been condemned by the Church. Commissioning a series of paintings that told the story diminished the risk of ecclesiastical censure – though not entirely. At various times the local bishop ordered their removal from the walls of the Augsburg convent. During the Second World War the paintings were removed and hidden, and therefore survived the destruction of the Augsburg convent. </p> 

	<p>The earlier paintings are better artistically than the later ones, and tell the story of <b>Mary Ward’s</b> early life, her vocation and the founding of her institute. Many of the later ones are artistically not remarkable but they contain a series of deep spiritual experiences that are not known from the written sources.</p>

	<p>The inscriptions on the paintings are written in German and were most likely added at the end of the seventeenth century after the completion of the pictures.  </p>

	<p>Copyright <b>‘Painted Life’ </b>Pictures. Photo Tanner, Nesselwang, Germany.</p>
<br />
<div class="flexbox-v zoompic maximspic zoom-gallery">
<div>
  <a href="/https://webapi.entab.info/api/image/LCSD/public/Images/painted-life-1.jpg" data-source="" title="The first word that Mary uttered was ‘Jesus’, after which she did not speak again for several months."> 
  <LazyLoad><img src="https://webapi.entab.info/api/image/LCSD/public/Images/painted-life-1.jpg"/></LazyLoad> </a></div>

<div>
 <a href="/https://webapi.entab.info/api/image/LCSD/public/Images/painted-life-2.jpg" data-source="" title="In the tenth year of her age, Mary was urged by her parents to marry a young man by the name of Redshaw, who was both rich and of noble birth. She prayed fervently, imploring God to prevent this marriage, if it were not to His glory and for the good of her soul. Her prayer was heard."> <LazyLoad><img src="https://webapi.entab.info/api/image/LCSD/public/Images/painted-life-2.jpg"/></LazyLoad> </a></div>

<div>
 <a href="/https://webapi.entab.info/api/image/LCSD/public/Images/painted-life-3.jpg" data-source="" title="In the tenth year of her age, Mary was urged by her parents to marry a In her tenth year, Mary had such a serious fall that she lost the power of speech. She thought within herself: O, how gladly would I die if only I could once say the saving Name of Jesus. As she pronounced it, she became quite well again and her heart was filled with such sweetness and love of God, that she never forgot it to the end of her life."> <LazyLoad><img src="https://webapi.entab.info/api/image/LCSD/public/Images/painted-life-3.jpg"/></LazyLoad> </a></div>

<div>
 <a href="/https://webapi.entab.info/api/image/LCSD/public/Images/painted-life-4.jpg" data-source="" title="In the year 1595 on the feast of the Annunciation, when Mary was in her eleventh year, a great fire broke out at her father&#8217;s house at Mulwith. She was not alarmed, but remained in the room, saying the rosary with her sisters until their father came to fetch them."> <LazyLoad><img src="https://webapi.entab.info/api/image/LCSD/public/Images/painted-life-4.jpg"/></LazyLoad> </a></div>

<div>
 <a href="/https://webapi.entab.info/api/image/LCSD/public/Images/painted-life-5.jpg" data-source="" title="When Mary was in her twelfth year, she was again urged by her parents to accept a very suitable offer of marriage, from a gentleman by the name of Shafto. She insisted on turning down this proposal, claiming that in God alone would she place her love."> <LazyLoad><img src="https://webapi.entab.info/api/image/LCSD/public/Images/painted-life-5.jpg"/></LazyLoad> </a></div>

<div>
 <a href="/https://webapi.entab.info/api/image/LCSD/public/Images/painted-life-6.jpg" data-source="" title="When Mary was thirteen, the devil came to her under the appearance of Francis Carle, her father&#8217;s servant. He was on horseback and read out a letter to her supposedly from her father. It contained strict orders that she was to postpone first communion until she received further instructions."> <LazyLoad><img src="https://webapi.entab.info/api/image/LCSD/public/Images/painted-life-6.jpg"/></LazyLoad> </a></div>

<div>
 <a href="/https://webapi.entab.info/api/image/LCSD/public/Images/painted-life-7.jpg" data-source="" title="In her thirteenth year, after overcoming many obstacles, Mary prepared with great zeal and devotion for her first communion. She received much light and knowledge from God on this occasion."> <LazyLoad><img src="https://webapi.entab.info/api/image/LCSD/public/Images/painted-life-7.jpg"/></LazyLoad> </a></div>

<div>
 <a href="/https://webapi.entab.info/api/image/LCSD/public/Images/painted-life-8.jpg" data-source="" title="When Mary was in her thirteenth year, at a time of threatened civil disturbance, she was sent to stay with a relative. She was frequently urged to become engaged to a young gentleman named Eldrington, who was of distinguished birth and had other good qualities. But her heart was so caught up in divine love that she could not consent to any earthly love. She was so stressed at being pressed to marry that she fell dangerously ill and her father came to fetch her home."> <LazyLoad><img src="https://webapi.entab.info/api/image/LCSD/public/Images/painted-life-8.jpg"/></LazyLoad> </a></div>

<div>
 <a href="/https://webapi.entab.info/api/image/LCSD/public/Images/painted-life-9.jpg" data-source="" title="One day, when Mary was fifteen, she sat sewing with her cousin Barbara Babthorpe while a devout woman, named Margaret Garrett, told them of the severe punishment inflicted on a religious whose conduct had given scandal. On hearing this story Mary received so much light from God on the excellency of the religious life that she decided to embrace this state."> <LazyLoad><img src="https://webapi.entab.info/api/image/LCSD/public/Images/painted-life-9.jpg"/></LazyLoad> </a></div>

<div>
 <a href="/https://webapi.entab.info/api/image/LCSD/public/Images/painted-life-10.jpg" data-source="" title="When Mary was sixteen she read the lives of the holy martyrs. She was taken up with such a burning desire to follow their example that she felt only martyrdom itself could satisfy her longing until God revealed to her interiorly that what was required of her was spiritual rather than bodily martyrdom."> <LazyLoad><img src="https://webapi.entab.info/api/image/LCSD/public/Images/painted-life-10.jpg"/></LazyLoad> </a></div>

<div>
 <a href="/https://webapi.entab.info/api/image/LCSD/public/Images/painted-life-11.jpg" data-source="" title="As Mary grew anxious that her desire for martyrdom was fading somewhat, she took herself to prayer, in case any lack of cooperation on her part should have caused this. Then God revealed to her that the martyrdom that was expected of her was to consist in the perfect observance of the three evangelical counsels in the religious life."> <LazyLoad><img src="https://webapi.entab.info/api/image/LCSD/public/Images/painted-life-11.jpg"/></LazyLoad> </a></div>

<div>
 <a href="/https://webapi.entab.info/api/image/LCSD/public/Images/painted-life-12.jpg" data-source="" title="When Mary was twenty all her friends both secular and religious tried to dissuade her from entering religious life. So she devoted herself day and night to prayer and penance imploring God to fulfil His will in her. At last Christ&#8217;s words, &#8216;Seek ye first the kingdom of God&#8217; came to her mind, and she felt sure of God&#8217;s assistance. She received such enlightenment that it gave her courage not only then, but also subsequently, to face insurmountable difficulties."> <LazyLoad><img src="https://webapi.entab.info/api/image/LCSD/public/Images/painted-life-12.jpg"/></LazyLoad> </a></div>

<div>
 <a href="/https://webapi.entab.info/api/image/LCSD/public/Images/painted-life-13.jpg" data-source="" title="In 1606, so as to test her fidelity, Mary was urged, not only by her parents but also by her confessor, to marry a young man named Neville, the sole heir of a ancient and noble Westmoreland family. But she heroically obeyed the call of God and did not give in to human persuasion."> <LazyLoad><img src="https://webapi.entab.info/api/image/LCSD/public/Images/painted-life-13.jpg"/></LazyLoad> </a></div>

<div>
 <a href="/https://webapi.entab.info/api/image/LCSD/public/Images/painted-life-14.jpg" data-source="" title="When Mary&#8217;s confessor was saying Mass in London in 1606, it happened that he inadvertently spilt the chalice. This brought about such a change in him that when Mary handed him a towel to dry his hands after Mass, he said to her with tears streaming from his eyes: &#8216;I will never more stand in the way of your religious desires, but help you all I can.&#8217; And so it happened."> <LazyLoad><img src="https://webapi.entab.info/api/image/LCSD/public/Images/painted-life-14.jpg"/></LazyLoad> </a></div>

<div>
 <a href="/https://webapi.entab.info/api/image/LCSD/public/Images/painted-life-15.jpg" data-source="" title="After Whitsuntide in 1606 Mary by then 21 left home with her confessor&#8217;s approval and her parents&#8217; consent. She took ship to Saint-Omer accompanied by Mrs Bentley into whose care she had been entrusted. She was filled with unspeakable joy at being able at last to enter the religious state, having so long and so ardently desired it."> <LazyLoad><img src="https://webapi.entab.info/api/image/LCSD/public/Images/painted-life-15.jpg"/></LazyLoad> </a></div>

<div>
 <a href="/https://webapi.entab.info/api/image/LCSD/public/Images/painted-life-16.jpg" data-source="" title="In 1609 when Mary was 24 years of age, with the approval of her confessor whom she had vowed to obey in all spiritual matters, she made a vow to return to England and to labour there for the salvation of souls, in conformity with her state. Her labours produced much good."> <LazyLoad><img src="https://webapi.entab.info/api/image/LCSD/public/Images/painted-life-16.jpg"/></LazyLoad> </a></div>

<div>
 <a href="/https://webapi.entab.info/api/image/LCSD/public/Images/painted-life-17.jpg" data-source="" title="At Coldham Hall in England Mary obtained the conversion of a wealthy but obstinately heretical lady, after many learned men had used all their zeal and eloquence in trying to convert her. The touch of Mary&#8217;s hands and her friendly words brought such a change in this lady that she exclaimed: &#8216;I will be a Catholic, confess my sins, and do everything to show that my conversion is genuine.&#8217; This she most zealously fulfilled before she died."> <LazyLoad><img src="https://webapi.entab.info/api/image/LCSD/public/Images/painted-life-17.jpg"/></LazyLoad> </a></div>

<div>
 <a href="/https://webapi.entab.info/api/image/LCSD/public/Images/painted-life-18.jpg" data-source="" title="Mary&#8217;s great zeal for souls and ardent desire to bring her aunt Miss Gray to the Catholic faith impelled her on one occasion to exchange her noble attire for the dress of her serving maid. She hoped to be more free to talk to her aunt in a prearranged house in London if she was in disguise."> <LazyLoad><img src="https://webapi.entab.info/api/image/LCSD/public/Images/painted-life-18.jpg"/></LazyLoad> </a></div>

<div>
 <a href="/https://webapi.entab.info/api/image/LCSD/public/Images/painted-life-19.jpg" data-source="" title="While Mary was in London, her zealous words and gift of persuasion led her aunt Miss Gray to talk to a Jesuit priest with a view to accepting the true faith. While there Mary succeeded in bringing back to the faith on her deathbed an obstinate heretic who received Holy Communion with great devotion."> <LazyLoad><img src="https://webapi.entab.info/api/image/LCSD/public/Images/painted-life-19.jpg"/></LazyLoad> </a></div>

<div>
 <a href="/https://webapi.entab.info/api/image/LCSD/public/Images/painted-life-20.jpg" data-source="" title="While Mary was in London a noble gentleman sent his servants to her with a gift of fine food. Mary feared, not without reason, that this might be a temptation from the evil one. So she locked herself in her room and spent the whole night in prayer and penance."> <LazyLoad><img src="https://webapi.entab.info/api/image/LCSD/public/Images/painted-life-20.jpg"/></LazyLoad> </a></div>

<div>
 <a href="/https://webapi.entab.info/api/image/LCSD/public/Images/painted-life-21.jpg" data-source="" title="One morning in 1609 Mary was in London and she had just made her meditation with, so she thought, very little fervour. Whilst dressing, she decided to make amends for this by giving a large sum of money to a person of good birth who desired to enter religious life, but had not got a dowry. At this moment Mary fell into an ecstasy during which she lost herself and her power of movement. While in this state she saw clearly that it was not God&#8217;s will for her to enter an austere order, but that she was called to a much more excellent state which would do far more to promote the glory of God. After a space of two hours, which seemed to her like a quarter of an hour, a long time passed before she could hear anything except the word GLORY."> <LazyLoad><img src="https://webapi.entab.info/api/image/LCSD/public/Images/painted-life-21.jpg"/></LazyLoad> </a></div>

<div>
 <a href="/https://webapi.entab.info/api/image/LCSD/public/Images/painted-life-22.jpg" data-source="" title="During her stay in London in 1609, Mary&#8217;s edifying life and persuasive words won over several young women of noble birth to the service of God. Inspired by her example and to avoid the temptations of the world they crossed over with her to Saint-Omer to serve God in the religious state under Mary&#8217;s direction."> <LazyLoad><img src="https://webapi.entab.info/api/image/LCSD/public/Images/painted-life-22.jpg"/></LazyLoad> </a></div>

<div>
 <a href="/https://webapi.entab.info/api/image/LCSD/public/Images/painted-life-23.jpg" data-source="" title="At Saint-Omer Mary feared she loved her confessor too much, so lying on the ground, she resolved to give him up and whatever else might in the least lessen in her the perfection of God’s love. Then Christ appeared visibly to her and said, &#8216;O silly child, it is not you, but I who chose him for you&#8217;. She felt entirely consoled and ceased to worry."> <LazyLoad><img src="https://webapi.entab.info/api/image/LCSD/public/Images/painted-life-23.jpg"/></LazyLoad> </a></div>

<div>
 <a href="/https://webapi.entab.info/api/image/LCSD/public/Images/painted-life-24.jpg" data-source="" title="In 1611, Mary was lying in bed at Saint-Omer beginning to recover from a mortal illness. She was all alone and in an extraordinary repose of mind when she understood quite plainly by an interior voice in what way she was to organise her Institute. This brought her so great light, consolation and strength that it was impossible for her to doubt that this knowledge came from the divine Truth who is unable to deceive."> <LazyLoad><img src="https://webapi.entab.info/api/image/LCSD/public/Images/painted-life-24.jpg"/></LazyLoad> </a></div>

<div>
 <a href="/https://webapi.entab.info/api/image/LCSD/public/Images/painted-life-25.jpg" data-source="" title="On the feast of All Saints in 1615, when in Saint-Omer, God showed Mary a ‘just soul’ in inexpressible beauty. All the virtues seemed to form a chain in it, as it were. It was not only cut off from everything earthly, but was perfectly detached from self and united with God. It also received true liberty of spirit, equanimity, heavenly wisdom and ability to accomplish whatever the perfection of the Institute required."> <LazyLoad><img src="https://webapi.entab.info/api/image/LCSD/public/Images/painted-life-25.jpg"/></LazyLoad> </a></div>

<div>
 <a href="/https://webapi.entab.info/api/image/LCSD/public/Images/painted-life-26.jpg" data-source="" title="Mary was at sea on the feast of St James 1618 when a dangerous mutiny broke out on board. Mary prayed to this holy Apostle as her particular patron in order to quell the mutiny. To the amazement of all calm was restored and Mary afterwards declared that she had never sought any favour from God through the intercession of this great prince of heaven without it being granted to her."> <LazyLoad><img src="https://webapi.entab.info/api/image/LCSD/public/Images/painted-life-26.jpg"/></LazyLoad> </a></div>

<div>
 <a href="/https://webapi.entab.info/api/image/LCSD/public/Images/painted-life-27.jpg" data-source="" title="When in London Mary was once meditating on the words &#8216;AND THOU SHALT CALL HIS NAME JESUS&#8217;. God showed her visibly a ‘just soul’, adorned with great glory, and made her clearly understand that all who should live according to their vocation in this Institute, would attain to such unspeakable beauty of soul. Their state would bring them to great perfection and make them like to Christ their Lord, the most perfect model of all virtue."> <LazyLoad><img src="https://webapi.entab.info/api/image/LCSD/public/Images/painted-life-27.jpg"/></LazyLoad> </a></div>

<div>
 <a href="/https://webapi.entab.info/api/image/LCSD/public/Images/painted-life-28.jpg" data-source="" title="In 1618 Mary was grieving over her sins with many tears, when God clearly revealed to her that she was to make satisfaction for them in this life. Feeling God very near to her, she asked to be shown her how this might be done. She saw inwardly that it must be by bearing joyfully all the trials that would befall her in the accomplishment of God’s holy Will."> <LazyLoad><img src="https://webapi.entab.info/api/image/LCSD/public/Images/painted-life-28.jpg"/></LazyLoad> </a></div>

<div>
 <a href="/https://webapi.entab.info/api/image/LCSD/public/Images/painted-life-29.jpg" data-source="" title="Mary was once praying earnestly for a priest whom she had rescued from an evil life; she feared that he had again yielded to temptation, but God allowed her to see his guardian angel, standing at the head of his bed and with arms stretched lovingly over him as if to protect him from all dangers. The angel said to her: &#8216;Do you not see how faithfully I keep watch over him?&#8217;"> <LazyLoad><img src="https://webapi.entab.info/api/image/LCSD/public/Images/painted-life-29.jpg"/></LazyLoad> </a></div>

<div>
 <a href="/https://webapi.entab.info/api/image/LCSD/public/Images/painted-life-30.jpg" data-source="" title="In 1619 at Saint-Omer when Mary was fervently thanking God for the grace of her vocation, She was shown clearly that to help to save souls is a far greater gift than the monastic life or even than martyrdom itself."> <LazyLoad><img src="https://webapi.entab.info/api/image/LCSD/public/Images/painted-life-30.jpg"/></LazyLoad> </a></div>

<div>
 <a href="/https://webapi.entab.info/api/image/LCSD/public/Images/painted-life-31.jpg" data-source="" title="In 1619 when Mary was meditating on the call of the Apostles, she perceived that they had no resting place in anything of this world but were entirely at their Lord’s disposal. This knowledge roused in her a renewed desire to attain perfect self-denial. Suddenly she experienced a feeling of complete liberty and detachment from earthly things, from the world and from created things."> <LazyLoad><img src="https://webapi.entab.info/api/image/LCSD/public/Images/painted-life-31.jpg"/></LazyLoad> </a></div>

<div>
 <a href="/https://webapi.entab.info/api/image/LCSD/public/Images/painted-life-32.jpg" data-source="" title="In 1619 Mary felt inwardly disturbed at living without sufferings, trials or opposition. She fancied these would purify her soul and render her works more pleasing to God. But when she laid these thoughts before Our Lord in the most Holy Sacrament of the altar, it was shown plainly to her that her distress on this account was not pleasing to God because it came from self-will. She therefore desired to abandon herself totally to God, and was ready to accept everything at God’s hand, just as it came."> <LazyLoad><img src="https://webapi.entab.info/api/image/LCSD/public/Images/painted-life-32.jpg"/></LazyLoad> </a></div>

<div>
 <a href="/https://webapi.entab.info/api/image/LCSD/public/Images/painted-life-33.jpg" data-source="" title="In 1619 at Liège during her meditation God showed Mary how many souls would be lost and how few saved. It was also revealed to her that the sole reason for the loss of so many souls was that they had refused to cooperate with the impulses to do good which had been given them to gain everlasting happiness. Their free will alone had condemned them to burn in hell."> <LazyLoad><img src="https://webapi.entab.info/api/image/LCSD/public/Images/painted-life-33.jpg"/></LazyLoad> </a></div>

<div>
 <a href="/https://webapi.entab.info/api/image/LCSD/public/Images/painted-life-34.jpg" data-source="" title="On October 10th 1619 Mary was in retreat and sought God with fervour, humility and great confidence to make known to her what He really is. She saw God enter into her heart and received the knowledge she had asked for."> <LazyLoad><img src="https://webapi.entab.info/api/image/LCSD/public/Images/painted-life-34.jpg"/></LazyLoad> </a></div>

<div>
 <a href="/https://webapi.entab.info/api/image/LCSD/public/Images/painted-life-35.jpg" data-source="" title="In 1619 in the Church of St Martin at Liège, before the crucifix near the sanctuary, God revealed to Mary that although the Institute was not to be altogether subject to the Society of Jesus, yet it ought to be under its direction, in order to preserve and not to deviate from its true spirit."> <LazyLoad><img src="https://webapi.entab.info/api/image/LCSD/public/Images/painted-life-35.jpg"/></LazyLoad> </a></div>

<div>
 <a href="/https://webapi.entab.info/api/image/LCSD/public/Images/painted-life-36.jpg" data-source="" title="In 1624 Mary obtained a speedy recovery from illness for his Eminence, Cardinal Trescio. The favour was granted to her through a pilgrimage to the shrine of the miraculous Madonna on Monte Giovino where she spent five hours in prayer for the Cardinal."> <LazyLoad><img src="https://webapi.entab.info/api/image/LCSD/public/Images/painted-life-36.jpg"/></LazyLoad> </a></div>

<div>
 <a href="/https://webapi.entab.info/api/image/LCSD/public/Images/painted-life-37.jpg" data-source="" title="In 1625 whilst Mary was praying during a journey, God made known to her the excellence of the religious state, showing her clearly that its strength does not consist in any temporal power, but in God alone before whose greatness she saw the power of all created beings melt away and vanish."> <LazyLoad><img src="https://webapi.entab.info/api/image/LCSD/public/Images/painted-life-37.jpg"/></LazyLoad> </a></div>

<div>
 <a href="/https://webapi.entab.info/api/image/LCSD/public/Images/painted-life-38.jpg" data-source="" title="In 1625 in Rome on the feast of Saint-Peter-in-Chains Mary was fervently commending her Institute to God. He made known to her that its prosperity, progress and security did not depend upon wealth, dignity and the favour of princes, but that all its members had free and open access to Him from whom proceed all strength, light and protection."> <LazyLoad><img src="https://webapi.entab.info/api/image/LCSD/public/Images/painted-life-38.jpg"/></LazyLoad> </a></div>

<div>
 <a href="/https://webapi.entab.info/api/image/LCSD/public/Images/painted-life-39.jpg" data-source="" title="In 1625 in Rome Mary prayed fervently to Our Lord hidden in the Blessed Sacrament in the Church of San Girólamo della Caritá. She most humbly implored God to teach her how her sufferings might be borne most profitably. She heard an interior voice, yet quite distinct, telling her that she would give God the greatest pleasure if she bore her trials joyfully."> <LazyLoad><img src="https://webapi.entab.info/api/image/LCSD/public/Images/painted-life-39.jpg"/></LazyLoad> </a></div>

<div>
 <a href="/https://webapi.entab.info/api/image/LCSD/public/Images/painted-life-40.jpg" data-source="" title="On April 11th 1625 Mary was in the Church of the Madonna dell&#8217;Orto in Rome praying before the Blessed Sacrament, when she clearly perceived her own nothingness and saw that God was all in all. She became so completely wrapped in the love of God that she was taken out of herself and rested in Him alone. The intense brilliancy of the rays of light proceeding from the Blessed Sacrament shone on her face and deprived her for a while of her sight."> <LazyLoad><img src="https://webapi.entab.info/api/image/LCSD/public/Images/painted-life-40.jpg"/></LazyLoad> </a></div>

<div>
 <a href="/https://webapi.entab.info/api/image/LCSD/public/Images/painted-life-41.jpg" data-source="" title="On June 26th 1625 Mary was praying before the Blessed Sacrament in the Church of S. Eligio in Rome, when she received from Our Lord so much light and knowledge regarding the forgiveness of enemies, that from that moment she cherished a tender affection for all who wronged her and was in the habit of calling them friends and lovers of her heavenly reward."> <LazyLoad><img src="https://webapi.entab.info/api/image/LCSD/public/Images/painted-life-41.jpg"/></LazyLoad> </a></div>

<div>
 <a href="/https://webapi.entab.info/api/image/LCSD/public/Images/painted-life-42.jpg" data-source="" title="In the year 1626 when Mary was praying for the Institute in Saint Mark&#8217;s Church in Rome, God reminded her interiorly of the words of Christ: &#8216;Can you drink the chalice that I shall drink?&#8217;, showing her what great adversities, persecutions and trials she would have to encounter in accomplishing His holy Will. She joyfully declared herself ready to accept everything."> <LazyLoad><img src="https://webapi.entab.info/api/image/LCSD/public/Images/painted-life-42.jpg"/></LazyLoad> </a></div>

<div>
 <a href="/https://webapi.entab.info/api/image/LCSD/public/Images/painted-life-43.jpg" data-source="" title="On Christmas Eve 1626 Mary arrived at Feldkirch in Tyrol. In spite of her tiredness and the intense cold, she remained in the parish church from eight o&#8217;clock in the evening until three o&#8217;clock in the morning absorbed in prayer."> <LazyLoad><img src="https://webapi.entab.info/api/image/LCSD/public/Images/painted-life-43.jpg"/></LazyLoad> </a></div>

<div>
 <a href="/https://webapi.entab.info/api/image/LCSD/public/Images/painted-life-44.jpg" data-source="" title="On Christmas Day 1626, Mary attended Mass in the Capuchin Church at Feldkirch and prayed most earnestly to the new-born Saviour for the conversion of the King of England. God revealed to her the infinitely tender love He had for the King and how much He desired him to share His eternal glory, but that the King&#8217;s cooperation was wanting."> <LazyLoad><img src="https://webapi.entab.info/api/image/LCSD/public/Images/painted-life-44.jpg"/></LazyLoad> </a></div>

<div>
 <a href="/https://webapi.entab.info/api/image/LCSD/public/Images/painted-life-45.jpg" data-source="" title="In 1626, when Mary was on her way to Munich for the first time, not far from the Isarberg she told her companions that God had revealed to her in prayer that His Highness the Elector would provide them with a suitable house and a yearly means of support. This effectively took place soon after their arrival in Munich."> <LazyLoad><img src="https://webapi.entab.info/api/image/LCSD/public/Images/painted-life-45.jpg"/></LazyLoad> </a></div>

<div>
 <a href="/https://webapi.entab.info/api/image/LCSD/public/Images/painted-life-46.jpg" data-source="" title="In 1626 Mary was once very sad because one of her companions was thinking of giving up her vocation. She had recourse to prayer, commending her Institute to God and His Blessed Mother. It was then clearly shown to her and she was given to discern when and by whom the Institute would be confirmed, and that this would be done at a time when least expected."> <LazyLoad><img src="https://webapi.entab.info/api/image/LCSD/public/Images/painted-life-46.jpg"/></LazyLoad> </a></div>

<div>
 <a href="/https://webapi.entab.info/api/image/LCSD/public/Images/painted-life-47.jpg" data-source="" title="Once when Mary was in dire need she prayed begging God to send her a certain sum of money. Then she heard clearly an interior voice asking her: &#8216;Is this sum of money better than my Providence?&#8217; From these words she perceived that from now on she was to regard all worldly riches as unimportant, and Divine Providence as her true wealth."> <LazyLoad><img src="https://webapi.entab.info/api/image/LCSD/public/Images/painted-life-47.jpg"/></LazyLoad> </a></div>

<div>
 <a href="/https://webapi.entab.info/api/image/LCSD/public/Images/painted-life-48.jpg" data-source="" title="When Mary was visiting one of her houses God allowed her to see that one of the novices was suffering great temptations against her vocation, because everything appeared hard and wearisome to her. Mary spoke affectionately to her saying: &#8216;Dear child, virtue is hard only to those who consider it to be so; your way to heaven must be to accept everything from God&#8217;s hand, and to seek Him alone in everything.&#8217; In these few words the novice found peace and was freed from all her temptations."> <LazyLoad><img src="https://webapi.entab.info/api/image/LCSD/public/Images/painted-life-48.jpg"/></LazyLoad> </a></div>

<div>
 <a href="/https://webapi.entab.info/api/image/LCSD/public/Images/painted-life-49.jpg" data-source="" title="When Mary was at Saint-Omer God showed her a distinguished-looking man unknown to her and dressed as a bishop. God revealed to her that this person, although a stranger, was a friend of her Institute."> <LazyLoad><img src="https://webapi.entab.info/api/image/LCSD/public/Images/painted-life-49.jpg"/></LazyLoad> </a></div>

<div>
 <a href="/https://webapi.entab.info/api/image/LCSD/public/Images/painted-life-50.jpg" data-source="" title="At Saint-Omer God granted Mary a sight of great glory, saying to her: &#8216;Be not weary, you shall die soon, and your reward shall be great.&#8217;"> <LazyLoad><img src="https://webapi.entab.info/api/image/LCSD/public/Images/painted-life-50.jpg"/></LazyLoad> </a></div>

</div>


      </div>


</div>
</div>
</section>
   </>
  )
}

export default PaintedLife