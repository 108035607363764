import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import LazyLoad from 'react-lazyload';

const Newsletter = () => {
    const [showPopup1, setShowPopup1] = useState(false);
    const [showPopup2, setShowPopup2] = useState(false);
    const [showPopup3, setShowPopup3] = useState(false);

    const togglePopup1 = () => {
        setShowPopup1(!showPopup1);
    };

    const togglePopup2 = () => {
        setShowPopup2(!showPopup2);
    };
    const togglePopup3 = () => {
        setShowPopup3(!showPopup3);
    };

    return (
        <>
            <section className="page-header">
                <div className="page-header-bg"></div>
                <div className="page-header-shape-1"><LazyLoad><img src="https://webapi.entab.info/api/image/LCSD/public/Images/page-header-shape-1.png" alt="Loreto Convent School, Delhi Cantt"/></LazyLoad></div>
                <div className="container">
                    <div className="page-header__inner">
                        <ul className="thm-breadcrumb list-unstyled ml-0">
                            <li><Link to="/"> Home</Link></li>
                            <li>Magazine   </li>
                            <li>  Newsletter </li>
                        </ul>
                        <h2> Newsletter </h2>
                    </div>
                </div>
            </section>
            <section className="innerbody">
                <div className="container">
                    <div className="row years year2023 showYear magazine">
                    <div className="col-lg-4 col-md-6">
                            <div className="magbox">
                                <h5>August 2024 Issue 1</h5>

                               <LazyLoad> <img src="https://webapi.entab.info/api/image/LCSD/public/Images/august-24-1.jpg" className="img-fluid mx-auto d-block" alt="July 2023 Issue 1" /></LazyLoad>
                            </div>
                            <div className="popup_main">
                                <button className="open_popup" name="data" type="button" onClick={togglePopup3}>Click to View</button>
                                {showPopup3 && (
                                    <div className="popup_body ">
                                        <div className="popup_back"></div>
                                        <div className="popup_contain">
                                            <div className="popup_close" onClick={togglePopup3}>x</div>
                                            <p>Use &gt; and &lt; arrows for change pages</p>
                                            <div className="gray-bg"  background="#eeeeee !important" ></div>
                                            <iframe src="https://online.fliphtml5.com/cepbx/ayjy/" width="100%" height="800px"></iframe>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="magbox">
                                <h5>October 2023 </h5>
                                <LazyLoad><img src="https://webapi.entab.info/api/image/LCSD/public/Images/newsletter-2.jpg" className="img-fluid mx-auto d-block" alt="October 2023" /></LazyLoad>
                            </div>
                            <div className="popup_main">
                                <button className="open_popup" name="data" type="button" onClick={togglePopup1}>Click to View</button>
                                {showPopup1 && (
                                    <div className="popup_body">
                                        <div className="popup_back"></div>
                                        <div className="popup_contain">
                                            <div className="popup_close" onClick={togglePopup1}>x</div>
                                            <p>Use &gt; and &lt; arrows for change pages</p>
                                            <div className="gray-bg"></div>
                                            <iframe src="https://online.fliphtml5.com/cgsbh/ttqr/" width="100%" height="800px"></iframe>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="magbox">
                                <h5>July 2023 Issue 1</h5>
                                <LazyLoad>  <img src="https://webapi.entab.info/api/image/LCSD/public/Images/newsletter-1.jpg" className="img-fluid mx-auto d-block" alt="July 2023 Issue 1" /></LazyLoad>
                            </div>
                            <div className="popup_main">
                                <button className="open_popup" name="data" type="button" onClick={togglePopup2}>Click to View</button>
                                {showPopup2 && (
                                    <div className="popup_body">
                                        <div className="popup_back"></div>
                                        <div className="popup_contain">
                                            <div className="popup_close" onClick={togglePopup2}>x</div>
                                            <p>Use &gt; and &lt; arrows for change pages</p>
                                            <div className="gray-bg"></div>
                                            <iframe src="https://online.fliphtml5.com/cgsbh/mnww/#p=1" width="100%" height="800px"></iframe>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                      
                    </div>
                </div>
            </section>
        </>
    );
};

export default Newsletter;
