import React, { useEffect } from 'react';
import Hightlight from '../Component/Hightlight'
import { NavLink, Link } from 'react-router-dom';
import LazyLoad from 'react-lazyload';
import jQuery from 'jquery';

const Header = () => {
   useEffect(() => {
      // jQuery code for menu initialization
      (function (jQuery) {
         jQuery.fn.menumaker = function (options) {
            var cssmenu = jQuery(this), settings = jQuery.extend({
               format: 'dropdown',
               sticky: false,
            },
               options);
            return this.each(function () {
               jQuery(this)
                  .find('.menubutton')
                  .on('click', function () {

                     jQuery(this).toggleClass('menu-opened');
                     var mainmenu = jQuery(this).next('ul');
                     if (mainmenu.hasClass('open')) {
                        mainmenu.slideToggle().removeClass('open');
                     } else {
                        mainmenu.addClass('open');
                        if (settings.format === 'dropdown') {
                           mainmenu.find('ul').show();
                        }
                     }
                  });
               cssmenu.find('li ul').parent().addClass('has-sub');
               var multiTg = function () {
                  cssmenu.find('.has-sub').append('<span class="submenu-button"></span>');
                  cssmenu.find('.submenu-button').on('click', function () {
                     jQuery(this).toggleClass('submenu-opened');
                     if (jQuery(this).siblings('ul').hasClass('open')) {
                        jQuery(this).siblings('ul').removeClass('open').slideToggle();
                     } else {
                        jQuery(this).siblings('ul').addClass('open').slideToggle();
                     }
                  });
               };
               if (settings.format === 'multitoggle') multiTg();
               else cssmenu.addClass('dropdown');
               if (settings.sticky === true) cssmenu.css('position', 'fixed');
               var resizeFix = function () {
                  var mediasize = 1217;
                  if (jQuery(window).width() > mediasize) {
                     cssmenu.find('ul').show();
                  }
                  if (jQuery(window).width() <= mediasize) {
                     cssmenu.find('ul').hide().removeClass('open');
                  }
               };
               resizeFix();
               return jQuery(window).on('resize', resizeFix);
            });
         };
      })(jQuery);
      jQuery(document).ready(function () {
         jQuery("#cssmenu").menumaker({
            format: "multitoggle"
         });
      });
   }, []); // Empty dependency array to ensure the effect runs only once on mount

   return (
      <section className="top-header multbg">
         <div className="container-fluid">
            <div className="row">
               <div className="col-lg-3 col-md-12">
                  <div className="logo">
                     <Link to="/"> <img src="https://webapi.entab.info/api/image/LCSD/public/Images/logo.png" className="img-fluid mx-auto d-block" alt="Loreto Convent School Delhi Cantt" /></Link>
                  </div>
               </div>
               <div className="col-lg-9 col-md-12 menu-p-0">
                  <div className="row">
                     <div className="col-md-12">
                        <div className="topbar">
                           <div className="col-md-12">
                              <Hightlight />
                           </div>
                        </div>
                     </div>
                     <div className="col-md-12">
                        <header>
                           <div className="header-menu navigation">
                              <nav id="cssmenu">
                                 <div id="head-mobile"></div>
                                 <div className="menubutton"></div>
                                 <ul>
                                    <li><Link to="/">Home</Link></li>
                                    <li>
                                       <Link to="#">About </Link>
                                       <ul>
                                          <li><NavLink to="/OurSchool">Our School</NavLink></li>
                                          <li><NavLink to="/LegendaryPast">Legendary Past</NavLink></li>
                                          <li><NavLink to="/JourneyLoreto">Journey of Loreto</NavLink></li>
                                          <li><NavLink to="/OurFounder">Our Founder</NavLink></li>
                                          <li><NavLink to="/SchoolMotto">School Motto</NavLink></li>
                                          <li><NavLink to="/PrincipalMessage">Principal's Message</NavLink></li>
                                          <li><NavLink to="/MissionStatement">Mission Statement</NavLink></li>
                                          <li><NavLink to="/VisionStatement">Vision Statement</NavLink></li>
                                          <li><NavLink to="/LoretoSchoolsInIndia">Loreto Schools in India</NavLink></li>
                                          <li><NavLink to="/Infrastructure">Infrastructure Facilities</NavLink></li>
                                          <li><NavLink to="https://webapi.entab.info/api/image/LCSD/public/pdf/CPP.pdf" target="_blank">Guidelines For Child Protection</NavLink></li>
                                       </ul>
                                    </li>
                                    <li>
                                       <Link to="#">Academics</Link>
                                       <ul>
                                          <li><NavLink to="/ValueEducation">Value Education </NavLink></li>
                                          <li><NavLink to="https://webapi.entab.info/api/image/LCSD/public/pdf/Examination-Guidelines.pdf" target="_blank">Examination Guidelines </NavLink></li>
                                          <li><NavLink to="/HealthPhysicalEdu">Health & Physical Education </NavLink></li>
                                          <li><NavLink to="/Circulars">Circulars </NavLink></li>
                                          <li><NavLink to="/Syllabus">Syllabus </NavLink></li>
                                          <li><NavLink to="/ReadingBooks">Suggested Reading Books  </NavLink></li>
                                       </ul>
                                    </li>
                                    <li>
                                       <Link to="#">Founder</Link>
                                       <ul>
                                          <li><NavLink to="/MaryWard">Mary Ward </NavLink></li>
                                          <li><NavLink to="/CompanionMaryWard">The Companion Of Mary Ward </NavLink></li>
                                          <li><NavLink to="/PaintedLife">Painted Life </NavLink></li>
                                          <li><NavLink to="/Maxims">Maxims </NavLink></li>
                                          <li><NavLink to="/MaryWardsStamp">Mary Ward Stamp </NavLink></li>
                                          <li><NavLink to="/OurSpirituality">Our Spirituality </NavLink></li>
                                       </ul>
                                    </li>
                                    <li>
                                       <Link to="#">Prospectus  </Link>
                                       <ul>
                                          <li><NavLink to="/ListofSubject">List of subject offered</NavLink></li>
                                          <li><NavLink to="/AssessmentDesign">Assessment Design</NavLink></li>
                                          <li><NavLink to="/OfficeHours">Office Hours</NavLink></li>
                                          <li><NavLink to="/SchoolHours">School Hours</NavLink></li>
                                          <li><NavLink to="/HowtoLearn">How to Learn</NavLink></li>
                                          <li><NavLink to="/ConductDiscipline">Conduct & Discipline</NavLink></li>
                                          <li><NavLink to="/VacationLeave">Vacation/Leave</NavLink></li>
                                          <li><NavLink to="/SchoolFee">School Fee</NavLink></li>
                                          <li><NavLink to="/Uniform">School Uniform</NavLink></li>
                                          <li><NavLink to="/Policy">Policy</NavLink></li>
                                          <li><NavLink to="/BusRules">Bus Rules</NavLink></li>
                                          <li><NavLink to="/ClassStrength">Class Strength</NavLink></li>
                                          <li><NavLink to="/SchoolReport">School Report</NavLink></li>
                                          <li><NavLink to="/AdmissionWithdrawal">Admission & Withdrawal </NavLink></li>
                                          <li><NavLink to="/EvaluationAssessment">Evaluation & Assessment</NavLink></li>
                                       </ul>
                                    </li>
                                    <li>
                                       <Link to="#">Gallery</Link>
                                       <ul>
                                          <li><NavLink to="/Gallery">photo gallery</NavLink></li>
                                          <li><NavLink to="/VideoGallery">video gallery</NavLink></li>
                                          <li><NavLink to="/ClassPhotographs">Class Photographs</NavLink></li>
                                       </ul>
                                    </li>
                                    <li><Link to="#">magazine</Link>
                                       <ul>
                                          <li><NavLink to="/e-magazine">E-magazine</NavLink></li>
                                          <li><NavLink to="/Newsletter">Newsletter</NavLink></li>
                                       </ul>
                                    </li>
                                    <li>
                                       <Link to="#">Achievements</Link>
                                       <ul>
                                          <li><NavLink to="/Achievements">Achievements</NavLink></li>
                                          <li><NavLink to="/TopperX">CBSE Toppers  X</NavLink></li>
                                          <li><NavLink to="/TopperXII">CBSE Toppers XII</NavLink></li>
                                          <li><NavLink to="/AllRoundExcellence">all round excellence</NavLink></li>
                                          <li><NavLink to="/AryabhataGanitChallenge">aryabhata ganit challenge</NavLink></li>
                                       </ul>
                                    </li>

                                    <li>
                                       <Link to="#">Office Bearers</Link>
                                       <ul>

                                          <li><NavLink to="https://webapi.entab.info/api/image/LCSD/public/pdf/SafetyCommittee.pdf" target="_blank">School Safety Committee</NavLink></li>
                                          <li><NavLink to="https://webapi.entab.info/api/image/LCSD/public/pdf/InternalComplaintsCommittee.pdf" target="_blank">Internal Complaints Committee</NavLink></li>

                        
                                          <li><NavLink to="/ManagementDetails">Management Details</NavLink></li>
                                          <li><NavLink to="/LoretoDelhiCommunity">Loreto Delhi community </NavLink></li>
                                          <li><NavLink to="/OurPrincipals">Our Principals</NavLink></li>
                                          <li><NavLink to="/StudentCouncil">Student Council</NavLink></li>
                                          <li><NavLink to="/OfficeStaff">Office Staff</NavLink></li>
                                          <li><NavLink to="/TeachingStaff">Teaching Staff</NavLink></li>
                                          <li><NavLink to="/PTA">PTA</NavLink></li>
                                          <li><NavLink to="/HeadGirls">Head Girls</NavLink></li>
                                          <li><NavLink to="/HeadGirlMessage">Head Girl's Message</NavLink></li>
                                          <li><NavLink to="/CyberWarrior">Cyber Warriors</NavLink></li>
                                       </ul>
                                    </li>
                                    <li>
                                       <Link to="#">Outreach</Link>
                                       <ul>
                                          <li><NavLink to="/VisitAWWAASHA">Visit to AWWA Asha School </NavLink></li>
                                          <li><NavLink to="/VisitAshalayam">Visit to Don Bosco Ashalayam</NavLink></li>
                                          <li><NavLink to="/EarthquakeTurkish">Earthquake Relief Donations to the Turkish Embassy </NavLink></li>
                                          <li><NavLink to="/AanchalSpecialSchool">Aanchal Special School Chanakyapuri </NavLink></li>
                                       </ul>
                                    </li>
                                    <li><NavLink to="/ContactUs">Contact  </NavLink>  </li>
                                 </ul>
                              </nav>
                           </div>

                        </header>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </section>
   );
}

export default Header;
