import React from 'react'
import { Link } from 'react-router-dom'
import LazyLoad from 'react-lazyload';
const OurPrincipals = () => {
  return (
    <>
    <section className="page-header">
        <div className="page-header-bg"></div>
        <div className="page-header-shape-1"><LazyLoad><img src="https://webapi.entab.info/api/image/LCSD/public/Images/page-header-shape-1.png" alt="Loreto Convent School, Delhi Cantt"/></LazyLoad></div>
        <div className="container">
        <div className="page-header__inner">
            <ul className="thm-breadcrumb list-unstyled ml-0">
                <li><Link to="/"> Home</Link></li>
                <li> Office Bearers </li>
                <li> Our Principals  </li>
            </ul>
            <h2>Our Principals </h2>
        </div>
        </div>
    </section>
    <section className="innerbody">
<div className="container">
   <div className="row pt-5">
   <div className="container">
      <div className="row Principals g-4">

         <div className="col-lg-3 col-md-6 col-12">
          <LazyLoad><img src="https://webapi.entab.info/api/image/LCSD/public/Images/principals/MonicaRozario.png" alt="Loreto Convent School, Delhi Cantt" className="img-fluid mx-auto d-block" /></LazyLoad>
          <h5>Sr. Monica Rozario<span>2019 - 2023</span></h5>
         </div>
         <div className="col-lg-3 col-md-6 col-12">
          <LazyLoad><img src="https://webapi.entab.info/api/image/LCSD/public/Images/principals/Archana.png" alt="Loreto Convent School, Delhi Cantt" className="img-fluid mx-auto d-block" /></LazyLoad>
          <h5>Sr. Archana IBVM<span>2017 - 2019</span></h5>
         </div>
         <div className="col-lg-3 col-md-6 col-12">
        <LazyLoad><img src="https://webapi.entab.info/api/image/LCSD/public/Images/principals/Sr_Mercia.png" alt="Loreto Convent School, Delhi Cantt" className="img-fluid mx-auto d-block" /></LazyLoad>
         <h5>Sr. Mercia D'souza<span>2015 - 2017</span></h5>
         </div>
         <div className="col-lg-3 col-md-6 col-12">
          <LazyLoad><img src="https://webapi.entab.info/api/image/LCSD/public/Images/principals/Sr.-Antoinette-Rodrigues.png" alt="Loreto Convent School, Delhi Cantt" className="img-fluid mx-auto d-block" /></LazyLoad>
           <h5>Sr. Antoinette Rodrigues<span>2013-2014</span></h5>
         </div>
          <div className="col-lg-3 col-md-6 col-12">
          <LazyLoad><img src="https://webapi.entab.info/api/image/LCSD/public/Images/principals/sr.sunita.png" alt="Loreto Convent School, Delhi Cantt" className="img-fluid mx-auto d-block" /></LazyLoad>
           <h5>Sr. Anita Maria Braganza<span>2011-2013</span></h5>
         </div>

         <div className="col-lg-3 col-md-6 col-12">
          <LazyLoad><img src="https://webapi.entab.info/api/image/LCSD/public/Images/principals/Agnes-Davies.png" alt="Loreto Convent School, Delhi Cantt" className="img-fluid mx-auto d-block" /></LazyLoad>
           <h5>Mrs. Agnes Davies<span>2008-2011</span></h5>
         </div>

     <div className="col-lg-3 col-md-6 col-12">
          <LazyLoad><img src="https://webapi.entab.info/api/image/LCSD/public/Images/principals/claire.png" alt="Loreto Convent School, Delhi Cantt" className="img-fluid mx-auto d-block" /></LazyLoad>
           <h5>Sr. M. Claire Macfarland<span>2005-2008</span></h5>
         </div>

 <div className="col-lg-3 col-md-6 col-12">
          <LazyLoad><img src="https://webapi.entab.info/api/image/LCSD/public/Images/principals/Sr.-Mercy-D'souza.png" alt="Loreto Convent School, Delhi Cantt" className="img-fluid mx-auto d-block" /></LazyLoad>
           <h5>Sr. Mercia D'souza<span>2001-2005</span></h5>
         </div>


 <div className="col-lg-3 col-md-6 col-12">
          <LazyLoad><img src="https://webapi.entab.info/api/image/LCSD/public/Images/principals/anne.png" alt="Loreto Convent School, Delhi Cantt" className="img-fluid mx-auto d-block" /></LazyLoad>
           <h5>Sr. Anne Marie Brown
<span>1997-2001</span></h5>
         </div>

 <div className="col-lg-3 col-md-6 col-12">
          <LazyLoad><img src="https://webapi.entab.info/api/image/LCSD/public/Images/principals/marion.png" alt="Loreto Convent School, Delhi Cantt" className="img-fluid mx-auto d-block" /></LazyLoad>
           <h5>Sr. Marion Vase<span>1994-1997</span></h5>
         </div>

 <div className="col-lg-3 col-md-6 col-12">
          <LazyLoad><img src="https://webapi.entab.info/api/image/LCSD/public/Images/principals/Sr.Eileen-McDonald.png" alt="Loreto Convent School, Delhi Cantt" className="img-fluid mx-auto d-block" /></LazyLoad>
           <h5>Sr. Eileen Mc Donald<span>1992-1994</span></h5>
         </div>
 <div className="col-lg-3 col-md-6 col-12">
          <LazyLoad><img src="https://webapi.entab.info/api/image/LCSD/public/Images/principals/marina.png" alt="Loreto Convent School, Delhi Cantt" className="img-fluid mx-auto d-block" /></LazyLoad>
           <h5>Sr. Marina Bastian
<span>1987-1992</span></h5>
         </div>


 <div className="col-lg-3 col-md-6 col-12">
          <LazyLoad><img src="https://webapi.entab.info/api/image/LCSD/public/Images/principals/Sr-Margaret-Murphy.png" alt="Loreto Convent School, Delhi Cantt" className="img-fluid mx-auto d-block" /></LazyLoad>
           <h5>Sr. Margaret Murphy<span>1984-1987</span></h5>
         </div>

 <div className="col-lg-3 col-md-6 col-12">
          <LazyLoad><img src="https://webapi.entab.info/api/image/LCSD/public/Images/principals/Sr.Eileen-McDonald.png" alt="Loreto Convent School, Delhi Cantt" className="img-fluid mx-auto d-block" /></LazyLoad>
           <h5>Sr. Eileen Mc Donald
<span>1981-1984</span></h5>
         </div>

<div className="col-lg-3 col-md-6 col-12">
          <LazyLoad><img src="https://webapi.entab.info/api/image/LCSD/public/Images/principals/Sr.Therese-Curran.png" alt="Loreto Convent School, Delhi Cantt" className="img-fluid mx-auto d-block" /></LazyLoad>
           <h5>Sr. M.J. Therese Curran
<span>1975-1981</span></h5>
         </div>


<div className="col-lg-3 col-md-6 col-12">
          <LazyLoad><img src="https://webapi.entab.info/api/image/LCSD/public/Images/principals/Sr-Bernandine-Renwick.png" alt="Loreto Convent School, Delhi Cantt" className="img-fluid mx-auto d-block" /></LazyLoad>
           <h5>Mother M. Bernadine Renwick<span>1972-1975</span></h5>
         </div>


<div className="col-lg-3 col-md-6 col-12">
          <LazyLoad><img src="https://webapi.entab.info/api/image/LCSD/public/Images/principals/Sr-Francis-Claire.png" alt="Loreto Convent School, Delhi Cantt" className="img-fluid mx-auto d-block" /></LazyLoad>
           <h5>Mother Francis Clare O'donnell<span>1964-1971</span></h5>
         </div>


      </div>
   </div>
</div>
</div>
</section>
   </>
  )
}

export default OurPrincipals