import React, { useEffect, useState } from "react";
import { getEvents } from "../Service/Api";
import Slider from "react-slick";
import { Link } from 'react-router-dom';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import LazyLoad from 'react-lazyload';
const HomeEvent = () => {
   const [data, setData] = useState([]);

   useEffect(() => {
      const getData = async () => {
         const datas = await getEvents();
         setData(datas);
      };
      getData();
   }, []);

   const settings = {
      dots: true,
      infinite: false,
      speed: 500,
      slidesToShow: 3,
      margin: 30,
      slidesToScroll: 1,
      initialSlide: 0,
      responsive: [
         {
            breakpoint: 1200,
            settings: {
               slidesToShow: 3,
               slidesToScroll: 1,
               infinite: true,
               dots: true
            }
         },
         {
            breakpoint: 1000,
            settings: {
               slidesToShow: 2,
               slidesToScroll: 1,
               infinite: true,
               dots: true
            }
         },
         {
            breakpoint: 700,
            settings: {
               slidesToShow: 1,
               slidesToScroll: 1,
               infinite: true,
               dots: true
            }
         }
      ]
   };

   return (
      <div className="slider-container">
         <Slider {...settings}>
            {data.length > 0 && data.map((item, index) => (
               <div key={index} className="slide-item">
                  <div className="bd-blog">
                     <div className="bd-blog-thumb">
                        <LazyLoad><img src={`https://webapi.entab.info/api/image/${item.images}`} alt="Loreto Convent School Delhi Cantt" className="img-fluid" /></LazyLoad>
                     </div>
                     <div className="bd-blog-content bd-blog-content-2">
                        <div className="bd-blog-date-2">
                           <span>{item.date}</span>
                        </div>
                        <div className="bd-blog-meta">
                        {item.attachments && item.attachments.length > 0 && (<span><i className="bi bi-paperclip"></i> <Link to={`https://webapi.entab.info/api/image/${item.attachments}`} target="_blank">Attachment</Link></span>)}
                           <span><i className="bi bi-geo-alt"></i>School Campus</span>
                        </div>
                        <h4 className="bd-blog-title">{item.title}</h4>
                     </div>
                  </div>
               </div>
            ))}
         </Slider>
      </div>
   );
}

export default HomeEvent;
