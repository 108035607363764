import React from 'react'
import { Link } from 'react-router-dom'
import LazyLoad from 'react-lazyload';
const ParentPortal = () => {
  return (
    <>
    <div className="erp_links"> 
        <Link to="https://loretodelhi.campuscare.cloud/" target="_blank">
          <LazyLoad><img src="https://webapi.entab.info/api/image/LCSD/public/Images/Campulogo.png" className="img-fluid"  alt="Loreto Convent School Delhi Cantt"/></LazyLoad>
          </Link> 
    </div>
    </>
  )
}

export default ParentPortal
