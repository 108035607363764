import React from 'react'
import { Link } from 'react-router-dom'
import LazyLoad from 'react-lazyload';
const LoretoSchoolsInIndia = () => {
  return (
    <>
    <section className="page-header">
        <div className="page-header-bg"></div>
        <div className="page-header-shape-1"><LazyLoad><img src="https://webapi.entab.info/api/image/LCSD/public/Images/page-header-shape-1.png" alt="Loreto Convent School, Delhi Cantt"/></LazyLoad></div>
        <div className="container">
        <div className="page-header__inner">
            <ul className="thm-breadcrumb list-unstyled ml-0">
                <li><Link to="/"> Home</Link></li>
                <li> About Us </li>
                <li> Loreto Schools In India   </li>
            </ul>
            <h2>Loreto Schools In India </h2>
        </div>
        </div>
    </section>
    <section className="innerbody">
<div className="container">
<div className="row">
<div className="col-md-12">
<table className="table table-striped table-bordered">
<tbody><tr>
                            <td>Loreto House School 7, <br/>Middleton Row, Kolkata PIN: 700 071</td>
                            <td>Loreto House College 7, <br/>Middleton Row, Kolkata – 700 071</td>
                            
                        
                        </tr>
                        <tr>
                            <td>Loreto Convent 1, <br/>Convent Lane P.O. Tengra Kolkata PIN: 700 015</td>
                            <td>Loreto, Bowbazar 65,<br/> B. B. Ganguli St. Kolkata – 700 072</td>
                            
                        
                        </tr>
                        <tr>
                            <td>Loreto Day School,<br/> Dharamtala 169, Lenin Sarani Kolkata – 700 013</td>
                            <td>Loreto Day School,<br/> Elliot Road 9, Elliot Road Kolkata – 700 016</td>
                            
                        
                        </tr>
                        <tr>
                            <td>Loreto Day School,<br/> Sealdah 122, A.J.C. Bose Road Kolkata – 700 014</td>
                            <td>Loreto Convent School<br/> Darjeeling 734 101 West Bengal</td>
                            
                        </tr>
                        <tr>
                            <td>Loreto Convent, Asansol,<br/> W. Bengal PIN: 713 301</td>
                            <td>Loreto Convent, Parade Road,<br/> Delhi Cantt, New Delhi PIN: 110 010</td>
                         
                        
                        </tr>
                        <tr>
                            <td>Loreto Convent, Intermediate College 99,<br/> Mahatma Gandhi Marg, Lucknow, Uttar Pradesh PIN: 226 001</td>
                            <td>St. Teresa’s Girls H. S. School<br/> Darjeeling 734 101 West Bengal</td>
                      
                        
                        </tr>
                        <tr>
                            <td>Loreto Convent, Shillong <br/> 793 003 Meghalaya</td>
                            <td>Loreto Convent Tara Hall Shimla <br/> 171 003 Himachal Pradesh</td>
                           
                        
                        </tr><tr>
                            <td>Loreto Convent Dorunda H.P.O.<br/> Ranchi Jharkhand PIN – 834 002</td>
                            <td>St. Agnes’, Loreto Day School 33,<br/> Station Road Lucknow - 226 001 Uttar Pradesh</td>
                           
                        
                        </tr>
                        <tr>
                            <td>Loreto Primary School Lolay <br/>P.O Dokandara Kalimpong 734 301 West Bengal</td>
                            <td>Loreto Junior High School <br/>P.O Panighatta Bazaar Via Belgachi Dist. Darjeeling West Bengal</td>
                        </tr>
                         <tr>
                            <td>Loreto Primary School<br/> P.O Sadam Via Melli South Sikkim 737 128</td>
                            <td>Loreto Education Development Centre<br/> Post Box 76 10/10, Narayan Path Dist. Sunsari Dharan 10 – East Nepal</td>
                        </tr>
                         <tr>
                            <td>Loreto Convent School Darjeeling <br/>734 101 West Bengal</td>
                            <td>St. Alfred’s High School <br/>Vill. &amp; P.O. Padrishibpur Dist. Barisal – 8282 BANGLADESH</td>
                        </tr>
                         
                        
                    </tbody></table>
</div>
</div>
</div>
</section>
   </>
  )
}

export default LoretoSchoolsInIndia