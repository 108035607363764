import React from 'react'
import { Link } from 'react-router-dom'
import LazyLoad from 'react-lazyload';
const StudentCouncil = () => {
   return (
      <>
         <section className="page-header">
            <div className="page-header-bg"></div>
            <div className="page-header-shape-1"><LazyLoad><img src="https://webapi.entab.info/api/image/LCSD/public/Images/page-header-shape-1.png" alt="Loreto Convent School, Delhi Cantt"/></LazyLoad></div>
            <div className="container">
               <div className="page-header__inner">
                  <ul className="thm-breadcrumb list-unstyled ml-0">
                     <li><Link to="/"> Home</Link></li>
                     <li> Office Bearers </li>
                     <li> Student Council  </li>
                  </ul>
                  <h2>Student Council </h2>
               </div>
            </div>
         </section>
         <section className="innerbody">
            <div className="container">
               <div className="row pt-5">
                  <div className="container">
                     <div className="row">



                        <div className="col-md-12">
                           <div className="accordion achievementacc" id="accordionExample">
                           <div class="accordion-item">
                                 <div className="card">
                                    <div className="card-header">
                                       <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">Student Council 2024-2025</button></div>
                                    <div id="collapseOne" className="collapse show" data-bs-parent="#accordionExample">
                                       <div className="card-body Council_mo">
                                          <table className="table table-striped table-bordered">
                                             <tbody>


                                                <tr>
                                                   <td><LazyLoad><img src="https://webapi.entab.info/api/image/LCSD/public/Images/2024-25/KAASHVI-MALHOTRA.jpg" style={{ width: "39px" }} /></LazyLoad></td>
                                                   <td><strong>KAASHVI MALHOTRA</strong> <br /> HEAD GIRL </td>
                                                   <td><LazyLoad><img src="https://webapi.entab.info/api/image/LCSD/public/Images/2024-25/SAMPURNA-CHAKRABORTY.jpg" style={{ width: "39px" }} /></LazyLoad></td>
                                                   <td><strong>SAMPURNA S CHAKRABORTY</strong> <br /> VICE HEAD GIRL </td>
                                                   <td><LazyLoad><img src="https://webapi.entab.info/api/image/LCSD/public/Images/2024-25/ABHILASHA.jpg" style={{ width: "39px" }} /></LazyLoad></td>
                                                   <td><strong>ABHILASHA</strong> <br />SPORTS CAPTAIN </td>
                                                </tr>


                                                <tr>
                                                   <td><LazyLoad><img src="https://webapi.entab.info/api/image/LCSD/public/Images/2024-25/ARSHIA-JOSE.jpg" style={{ width: "39px" }} /></LazyLoad></td>
                                                   <td><strong>ARSHIA JOSE</strong> <br /> SPORTS VICE CAPTAIN </td>
                                                   <td><LazyLoad><img src="https://webapi.entab.info/api/image/LCSD/public/Images/2024-25/AVNI_WADHWA.jpg" style={{ width: "39px" }} /></LazyLoad></td>
                                                   <td><strong>AVNI WADHWA</strong> <br /> SPORTS PREFECT</td>
                                                   <td><LazyLoad><img src="https://webapi.entab.info/api/image/LCSD/public/Images/2024-25/DISHITA_SAHU.jpg" style={{ width: "39px" }} /></LazyLoad></td>
                                                   <td><strong>DISHITA SAHU</strong> <br /> BALL HOUSE CAPTAIN</td>


                                                </tr>

                                                <tr>

                                                   <td><LazyLoad><img src="https://webapi.entab.info/api/image/LCSD/public/Images/2024-25/LIVYA.jpg" style={{ width: "39px" }} /></LazyLoad></td>
                                                   <td><strong>LIVYA</strong> <br /> BALL HOUSE VICE CAPΤΑΙΝ </td>
                                                   <td><LazyLoad><img src="https://webapi.entab.info/api/image/LCSD/public/Images/2024-25/SARAH_ALI.jpg" style={{ width: "39px" }} /></LazyLoad></td>
                                                   <td><strong>SARAH ALI RIZVI</strong> <br /> BALL HOUSE PREFECT</td>
                                                   <td><LazyLoad><img src="https://webapi.entab.info/api/image/LCSD/public/Images/2024-25/YASHIKA_SHARMA.jpg" style={{ width: "39px" }} /></LazyLoad></td>
                                                   <td><strong>YASHIKA SHARMA</strong> <br /> HART HOUSE CAPTAIN </td>

                                                </tr>


                                                <tr>
                                                   <td><LazyLoad><img src="https://webapi.entab.info/api/image/LCSD/public/Images/2024-25/VAISHNAVI-SHARMA.jpg" style={{ width: "39px" }} /></LazyLoad></td>
                                                   <td><strong>VAISHNAVI SHARMA</strong> <br /> HART HOUSE VICE CAPΤΑΙΝ</td>
                                                   <td><LazyLoad><img src="https://webapi.entab.info/api/image/LCSD/public/Images/2024-25/UNNATI-GAUTAM.jpg" style={{ width: "39px" }} /></LazyLoad></td>
                                                   <td><strong>UNNATI GAUTAM</strong> <br /> HART HOUSE PREFECT </td>
                                                   <td><LazyLoad><img src="https://webapi.entab.info/api/image/LCSD/public/Images/2024-25/ALEENA-ALEX.jpg" style={{ width: "39px" }} /></LazyLoad></td>
                                                   <td><strong>ALEENA ALEX</strong> <br /> WALSH HOUSE CAPTAIN </td>

                                                </tr>
                                                <tr>

                                                   <td><LazyLoad><img src="https://webapi.entab.info/api/image/LCSD/public/Images/2024-25/DIVYANSHIKA-DHAWAN.jpg" style={{ width: "39px" }} /></LazyLoad></td>
                                                   <td><strong>DIVYANSHIKA DHAWAN</strong> <br /> WALSH HOUSE VICE CAPTAIN</td>
                                                   <td><LazyLoad><img src="https://webapi.entab.info/api/image/LCSD/public/Images/2024-25/ESPERANZA-RANGA.jpg" style={{ width: "39px" }} /></LazyLoad></td>
                                                   <td><strong>ESPERANZA RANGA</strong> <br /> WALSH HOUSE PREFECT </td>
                                                   <td><LazyLoad><img src="https://webapi.entab.info/api/image/LCSD/public/Images/2024-25/SIONA-SATIJA.jpg" style={{ width: "39px" }} /></LazyLoad></td>
                                                   <td><strong>SIONA SATIJA</strong> <br /> WARD HOUSE CAPTAIN </td>


                                                </tr>
                                                <tr>
                                                   <td><LazyLoad><img src="https://webapi.entab.info/api/image/LCSD/public/Images/2024-25/ALINA-BAIJU.jpg" style={{ width: "39px" }} /></LazyLoad></td>
                                                   <td><strong>ALINA BAIJU JOHN</strong> <br /> WARD HOUSE VICE CAPTAIN</td>
                                                   <td><LazyLoad><img src="https://webapi.entab.info/api/image/LCSD/public/Images/2024-25/VANSHIKA-CHHIKARA.jpg" style={{ width: "39px" }} /></LazyLoad></td>
                                                   <td><strong>VANSHIKA CHHIKARA</strong> <br /> WARD HOUSE PREFECT </td>
                                                   <td> </td>
                                                   <td><strong>-</strong> <br /> - </td>
                                                </tr>
                                             </tbody>
                                          </table>
                                       </div>
                                    </div>
                                 </div>
                              </div>

                              <div class="accordion-item">
                                 <div className="card">
                                    <div className="card-header">
                                       <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">Student Council 2023-2024</button></div>
                                    <div id="collapseOne" className="collapse" data-bs-parent="#accordionExample">
                                       <div className="card-body Council_mo">
                                          <table className="table table-striped table-bordered">
                                             <tbody>


                                                <tr>
                                                   <td><LazyLoad><img src="https://webapi.entab.info/api/image/LCSD/public/Images/2023-24/student_council/ANGELA ELIZABETH DOMINGO.JPG" style={{ width: "39px" }} /></LazyLoad></td>
                                                   <td><strong>ANGELA ELIZABETH DOMINGO</strong> <br /> HEAD GIRL </td>
                                                   <td><LazyLoad><img src="https://webapi.entab.info/api/image/LCSD/public/Images/2023-24/student_council/KAASHVI MALHOTRA.JPG" style={{ width: "39px" }} /></LazyLoad></td>
                                                   <td><strong>KAASHVI MALHOTRA</strong> <br /> VICE HEAD GIRL </td>
                                                   <td>-</td>     <td>-</td>
                                                </tr>


                                                <tr>
                                                   <td><LazyLoad><img src="https://webapi.entab.info/api/image/LCSD/public/Images/2023-24/student_council/STUTI JADAM.JPG" style={{ width: "39px" }} /></LazyLoad></td>
                                                   <td><strong>STUTI JADAM</strong> <br /> SPORTS CAPTAIN </td>
                                                   <td><LazyLoad><img src="https://webapi.entab.info/api/image/LCSD/public/Images/2023-24/student_council/ABHILASHA.JPG" style={{ width: "39px" }} /></LazyLoad></td>
                                                   <td><strong>ABHILASHA</strong> <br /> SPORTS VICE CAPTAIN </td>
                                                   <td><LazyLoad><img src="https://webapi.entab.info/api/image/LCSD/public/Images/2023-24/student_council/TANYA MISRA.JPG" style={{ width: "39px" }} /></LazyLoad></td>
                                                   <td><strong>TANYA MISRA</strong> <br /> SPORTS PREFECT </td>


                                                </tr>

                                                <tr>

                                                   <td><LazyLoad><img src="https://webapi.entab.info/api/image/LCSD/public/Images/2023-24/student_council/KERYL ANGEL ANTHONY.JPG" style={{ width: "39px" }} /></LazyLoad></td>
                                                   <td><strong>KERYL ANGEL ANTHONY</strong> <br /> BALL HOUSE CAPTAIN </td>
                                                   <td><LazyLoad><img src="https://webapi.entab.info/api/image/LCSD/public/Images/2023-24/student_council/DISHITA SAHU.JPG" style={{ width: "39px" }} /></LazyLoad></td>
                                                   <td><strong>DISHITA SAHU</strong> <br /> BALL HOUSE VICE CAPTAIN </td>
                                                   <td><LazyLoad><img src="https://webapi.entab.info/api/image/LCSD/public/Images/2023-24/student_council/MYRA ANAND.JPG" style={{ width: "39px" }} /></LazyLoad></td>
                                                   <td><strong>MYRA ANAND</strong> <br /> BALL HOUSE PREFECT </td>

                                                </tr>


                                                <tr>
                                                   <td><LazyLoad><img src="https://webapi.entab.info/api/image/LCSD/public/Images/2023-24/student_council/SANJANA SHARMA.JPG" style={{ width: "39px" }} /></LazyLoad></td>
                                                   <td><strong>SANJANA SHARMA</strong> <br /> HART HOUSE CAPTAIN </td>
                                                   <td><LazyLoad><img src="https://webapi.entab.info/api/image/LCSD/public/Images/2023-24/student_council/EVELEEN KAUR.JPG" style={{ width: "39px" }} /></LazyLoad></td>
                                                   <td><strong>EVELEEN KAUR</strong> <br /> HART HOUSE VICE CAPTAIN </td>
                                                   <td><LazyLoad><img src="https://webapi.entab.info/api/image/LCSD/public/Images/2023-24/student_council/HARSHDEEP KAUR.JPG" style={{ width: "39px" }} /></LazyLoad></td>
                                                   <td><strong>HARSHDEEP KAUR</strong> <br /> HART HOUSE PREFECT </td>

                                                </tr>
                                                <tr>

                                                   <td><LazyLoad><img src="https://webapi.entab.info/api/image/LCSD/public/Images/2023-24/student_council/AASHKA SRIVASTAVA.JPG" style={{ width: "39px" }} /></LazyLoad></td>
                                                   <td><strong>AASHKA SRIVASTAVA</strong> <br /> WALSH HOUSE CAPTAIN </td>
                                                   <td><LazyLoad><img src="https://webapi.entab.info/api/image/LCSD/public/Images/2023-24/student_council/ASMI KUMAR.JPG" style={{ width: "39px" }} /></LazyLoad></td>
                                                   <td><strong>ASMI KUMAR</strong> <br /> WALSH HOUSE VICE CAPTAIN </td>
                                                   <td><LazyLoad><img src="https://webapi.entab.info/api/image/LCSD/public/Images/2023-24/student_council/ALEENA ALEX.JPG" style={{ width: "39px" }} /></LazyLoad></td>
                                                   <td><strong>ALEENA ALEX</strong> <br /> WALSH HOUSE PREFECT </td>


                                                </tr>
                                                <tr>
                                                   <td><LazyLoad><img src="https://webapi.entab.info/api/image/LCSD/public/Images/2023-24/student_council/SANCHARI CHATTERJEE.JPG" style={{ width: "39px" }} /></LazyLoad></td>
                                                   <td><strong>SANCHARI CHATTERJEE</strong> <br /> WARD HOUSE CAPTAIN </td>
                                                   <td><LazyLoad><img src="https://webapi.entab.info/api/image/LCSD/public/Images/2023-24/student_council/SIONA SATIJA.JPG" style={{ width: "39px" }} /></LazyLoad></td>
                                                   <td><strong>SIONA SATIJA</strong> <br /> WARD HOUSE VICE CAPTAIN </td>
                                                   <td><LazyLoad><img src="https://webapi.entab.info/api/image/LCSD/public/Images/2023-24/student_council/ISHITA TOMAR.JPG" style={{ width: "39px" }} /></LazyLoad></td>
                                                   <td><strong>ISHITA TOMAR</strong> <br /> WARD HOUSE PREFECT </td>
                                                </tr>
                                             </tbody>
                                          </table>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                              <div class="accordion-item">
                                 <div className="card">
                                    <div className="card-header"><button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">Student Council 2022-2023</button></div>



                                    <div id="collapseTwo" className="collapse" data-bs-parent="#accordionExample">
                                       <div className="card-body Council_mo">
                                          <table className="table table-striped table-bordered">
                                             <tbody>
                                                <tr>
                                                   <td>
                                                      <LazyLoad>
                                                      <img src="https://webapi.entab.info/api/image/LCSD/public/Images/2022-23/student_council/Meryl-Teresa-Anthony.jpg" style={{ width: "39px" }} /></LazyLoad>
                                                   </td>
                                                   <td>                    <strong></strong>
                                                      <strong>Meryl Teresa Anthony </strong>
                                                      <br />
                                                      Head Girl
                                                   </td>
                                                   <td><LazyLoad>
                                                      <img src="https://webapi.entab.info/api/image/LCSD/public/Images/2022-23/student_council/Domingo.jpg" style={{ width: "39px" }} /></LazyLoad>
                                                   </td>
                                                   <td>
                                                      <strong>Angela Elizabeth Domingo </strong>
                                                      <br />
                                                      Vice Head Girl
                                                   </td>
                                                   <td>-</td><td>-</td>
                                                </tr>
                                                <tr>
                                                   <td><LazyLoad>
                                                      <img src="https://webapi.entab.info/api/image/LCSD/public/Images/2022-23/student_council/Ridhima-Rana.jpg" style={{ width: "39px" }} /></LazyLoad>
                                                   </td>
                                                   <td>
                                                      <strong>Ridhima Rana </strong>
                                                      <br />
                                                      Sports Captain
                                                   </td>
                                                   <td><LazyLoad>
                                                      <img src="https://webapi.entab.info/api/image/LCSD/public/Images/2022-23/student_council/Aditi-Sharma.jpg" style={{ width: "39px" }} /></LazyLoad>
                                                   </td>
                                                   <td>
                                                      <strong>Aditi Sharma </strong>
                                                      <br />
                                                      Sports Vice Captain
                                                   </td>
                                                   <td><LazyLoad>
                                                      <img src="https://webapi.entab.info/api/image/LCSD/public/Images/2022-23/student_council/Meghal.jpg" style={{ width: "39px" }} /></LazyLoad>
                                                   </td>
                                                   <td>
                                                      <strong>Meghal Mandhotra </strong>
                                                      <br />
                                                      Sports Prefect
                                                   </td>

                                                </tr>






                                                <tr> <td><LazyLoad>
                                                   <img src="https://webapi.entab.info/api/image/LCSD/public/Images/2022-23/student_council/Pratishtha.jpg" style={{ width: "39px" }} /></LazyLoad>
                                                </td>
                                                   <td>
                                                      <strong>Pratishtha </strong>
                                                      <br />
                                                      Ball House Captain
                                                   </td>
                                                   <td><LazyLoad>
                                                      <img src="https://webapi.entab.info/api/image/LCSD/public/Images/2022-23/student_council/Stuti-Jadam.jpg" style={{ width: "39px" }} /></LazyLoad>
                                                   </td>
                                                   <td>
                                                      <strong>Stuti Jadam </strong>
                                                      <br />
                                                      Ball House Vice Captain
                                                   </td>
                                                   <td><LazyLoad>
                                                      <img src="https://webapi.entab.info/api/image/LCSD/public/Images/2022-23/student_council/Vritte-Verma.jpg" style={{ width: "39px" }} /></LazyLoad>
                                                   </td>
                                                   <td>
                                                      <strong>Vritte Verma</strong>
                                                      <br />
                                                      Ball House Prefect
                                                   </td></tr>



                                                <tr>
                                                   <td><LazyLoad>
                                                      <img src="https://webapi.entab.info/api/image/LCSD/public/Images/2022-23/student_council/Alicia-Elizabeth.jpg" style={{ width: "39px" }} /></LazyLoad>
                                                   </td>
                                                   <td>
                                                      <strong>Alicia Elizabeth John</strong>
                                                      <br />
                                                      Hart House Captain
                                                   </td>

                                                   <td><LazyLoad>
                                                      <img src="https://webapi.entab.info/api/image/LCSD/public/Images/2022-23/student_council/P-V-Lakshmi.jpg" style={{ width: "39px" }} /></LazyLoad>
                                                   </td>
                                                   <td>
                                                      <strong>P. V. Lakshmi Manasa </strong>
                                                      <br />
                                                      Hart House Vice Captain
                                                   </td>

                                                   <td><LazyLoad>
                                                      <img src="https://webapi.entab.info/api/image/LCSD/public/Images/2022-23/student_council/Sanjana-Sharma.jpg" style={{ width: "39px" }} /></LazyLoad>
                                                   </td>
                                                   <td>
                                                      <strong>Sanjana Sharma </strong>
                                                      <br />
                                                      Hart House Prefect
                                                   </td>



                                                </tr>
                                                <tr>

                                                   <td><LazyLoad>
                                                      <img src="https://webapi.entab.info/api/image/LCSD/public/Images/2022-23/student_council/Sarah.jpg" style={{ width: "39px" }} /></LazyLoad>
                                                   </td>
                                                   <td>
                                                      <strong>Sarah Singh </strong>
                                                      <br />
                                                      Walsh House Captain
                                                   </td>


                                                   <td><LazyLoad>
                                                      <img src="https://webapi.entab.info/api/image/LCSD/public/Images/2022-23/student_council/Avika.jpg" style={{ width: "39px" }} /></LazyLoad>
                                                   </td>
                                                   <td>
                                                      <strong>Avika Kumar </strong>
                                                      <br />
                                                      Walsh House Vice Captain
                                                   </td>
                                                   <td><LazyLoad>
                                                      <img src="https://webapi.entab.info/api/image/LCSD/public/Images/2022-23/student_council/Aashka-Shrivastava.jpg" style={{ width: "39px" }} /></LazyLoad>
                                                   </td>
                                                   <td>
                                                      <strong>Aashka Shrivastava </strong>
                                                      <br />
                                                      Walsh House Prefect
                                                   </td>

                                                </tr>
                                                <tr>


                                                   <td><LazyLoad>
                                                      <img src="https://webapi.entab.info/api/image/LCSD/public/Images/2022-23/student_council/Aashka-Shrivastava.jpg" style={{ width: "39px" }} /></LazyLoad>
                                                   </td>
                                                   <td>
                                                      <strong>Dolly Kumar </strong>
                                                      <br />
                                                      Ward House Captain
                                                   </td>
                                                   <td><LazyLoad>
                                                      <img src="https://webapi.entab.info/api/image/LCSD/public/Images/2022-23/student_council/Sanchari-Chatterjee.jpg" style={{ width: "39px" }} /></LazyLoad>
                                                   </td>
                                                   <td>
                                                      <strong>Sanchari Chatterjee </strong>
                                                      <br />
                                                      Ward House Vice Captain
                                                   </td>

                                                   <td><LazyLoad>
                                                      <img src="https://webapi.entab.info/api/image/LCSD/public/Images/2022-23/student_council/Parnali.jpg" style={{ width: "39px" }} /></LazyLoad>
                                                   </td>
                                                   <td>
                                                      <strong>Parnali Debsharma </strong>
                                                      <br />
                                                      Ward House Prefect
                                                   </td>
                                                </tr>
                                             </tbody>
                                          </table>
                                       </div>
                                    </div>

                                 </div>
                              </div>
                           </div>

                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </section>
      </>
   )
}

export default StudentCouncil