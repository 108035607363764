import React from 'react'
import { Link } from 'react-router-dom'
import LazyLoad from 'react-lazyload';
const Pphelp = () => {
  return (
    <>
    <section className="page-header">
        <div className="page-header-bg"></div>
        <div className="page-header-shape-1"><LazyLoad><img src="https://webapi.entab.info/api/image/LCSD/public/Images/page-header-shape-1.png" alt="Loreto Convent School, Delhi Cantt"/></LazyLoad></div>
        <div className="container">
        <div className="page-header__inner">
            <ul className="thm-breadcrumb list-unstyled ml-0">
                <li><Link to="/"> Home</Link></li>
                <li> Portal (Help?)  </li>
            </ul>
            <h2>Portal (Help?) </h2>
        </div>
        </div>
    </section>
    <section className="innerbody">
<div className="container">
<div className="row">
<div className="col-md-12">
<h1 className="head1">CampusCare® Application Guidelines &amp; Instructions</h1>
<p className="guideheading">How to LOG IN Parent Portal?</p>
<p className="details">
   1. Open Chrome <LazyLoad><img src="https://webapi.entab.info/api/image/LCSD/public/Images/google.png" alt="School ERP Software, Mobile App, School Management Software" className="img-fluid"/></LazyLoad> OR Mozilla
   <LazyLoad> <img src="https://webapi.entab.info/api/image/LCSD/public/Images/mzilla.png" alt="School ERP Software, Mobile App, School Management Software" className="img-fluid"/></LazyLoad> or Internet
   <LazyLoad> <img alt="School ERP Software, Mobile App, School Management Software" src="https://webapi.entab.info/api/image/LCSD/public/Images/internet.png" className="img-fluid"/></LazyLoad>
</p>
<p className="details">
   2. Write the school URL <strong><Link target="_blank" to="https://loretodelhi.campuscare.cloud/">“loretodelhi.campuscare.cloud”</Link></strong> in address bar. (you will be routed to the Log In credential page).
</p>
<p className="details">
   3. Then, enter the USER ID and Click on "Next button", Then enter PASSWORD and Click on Sign in button.
</p>
<p className="details">
   Once logged in, you are welcomed with various information relating to assignments, news, circulars, date sheet, fee details etc. that are self explanatory. Wish you to go through every links that are provided and
   explore more.
</p>
<div className="p2"></div>


<div>
   <p className="guideheading">How to make Online Payment ?</p>
 <p className="details"><Link to="https://smarthubeducation.hdfcbank.com/SmartFees/Landing.action?instId=LED" target="_blank"><b>Click The Link </b></Link></p>
</div>





<p className="guideheading">How to RESET PASSWORD in Parent Portal? </p>
<p className="details">
   1. Open Chrome <LazyLoad><img src="https://webapi.entab.info/api/image/LCSD/public/Images/google.png" alt="School ERP Software, Mobile App, School Management Software" className="img-fluid"/></LazyLoad> OR Mozilla
   <LazyLoad><img src="https://webapi.entab.info/api/image/LCSD/public/Images/mzilla.png" alt="School ERP Software, Mobile App, School Management Software" className="img-fluid"/> </LazyLoad>or Internet
   <LazyLoad><img alt="School ERP Software, Mobile App, School Management Software" src="https://webapi.entab.info/api/image/LCSD/public/Images/internet.png" className="img-fluid"/></LazyLoad>
</p>
<p className="details">
   2. Write the school URL <strong><Link target="_blank" to="https://loretodelhi.campuscare.cloud/">“loretodelhi.campuscare.cloud”</Link></strong> in address bar. (you will be routed to the Log In credential page).
</p>
<p className="details">3. Then, click on “Forgot User ID or Password?” text given below User ID field.</p>
<p className="details">4. Select the User Type (For which you want a password) then Enter Registered Mobile No. and click on “Reset” Button.</p>
<p className="details">5. You will get an SMS on your registered mobile no. along with login credentials.</p>
<div className="clr10"></div>
<div>
   <p className="guideheading">How to Install &amp; operate MOBILE APP? </p>
   <p className="details">
      1. Go to <LazyLoad><img alt="School ERP Software, Mobile App, School Management Software" src="https://webapi.entab.info/api/image/LCSD/public/Images/playstore.png"/></LazyLoad> Store /
      <LazyLoad> <img alt="School ERP Software, Mobile App, School Management Software" src="https://webapi.entab.info/api/image/LCSD/public/Images/apple.png"/> </LazyLoad>and Search "CampusCare®".
   </p>
   <p className="details">
      2. Install and open the <Link to="https://www.entab.in/mobile-apps.html" target="_blank"><b>Mobile App</b></Link>.
   </p>
   <p className="details">
      3. Enter school web portal URL: <strong><Link target="_blank" to="https://loretodelhi.campuscare.cloud/">“loretodelhi.campuscare.cloud”</Link></strong> in link bar &amp; verify.
   </p>
   <p className="details">4. After verifying, you will get an option for User ID &amp; Password.</p>
   <p className="details">
      Once logged in, you are welcomed with various information relating to assignments, news. circulars, date sheet, fee details etc that are self Explanatory. Wish you to go through every links that are provided and
      explore more.
   </p>
   <p className="details">
      <strong style={{color: "#ea1f17"}}>NOTE* :</strong> Kindly update your <Link to="https://www.entab.in/mobile-apps.html" target="_blank"><b>Mobile Apps</b></Link> time to time to avoid difficulties during usage.
   </p>
</div>
<div className="clr10"></div>
<div className="assistance">
   <h5 className="guideheading">Please call or write for assistance:</h5>
   <p className="details">
      <strong> <Link to="https://www.entab.in/contact-us.html" target="_blank">PARENT HELP DESK</Link></strong> ( 8 AM to 8:00 PM ) <strong> E-Mail :</strong>
      <strong><Link to="mailto:parentdesk@entab.in" className="yellow-link"> parentdesk@entab.in</Link></strong>
   </p>
</div>      
</div>
</div>
</div>
</section>
   </>
  )
}

export default Pphelp