import React from 'react'
import LazyLoad from 'react-lazyload';
import { Link } from 'react-router-dom'
const VisitAWWAASHA = () => {
  return (
    <>
    <section className="page-header">
        <div className="page-header-bg"></div>
        <div className="page-header-shape-1">
        <LazyLoad><img src="https://webapi.entab.info/api/image/LCSD/public/Images/page-header-shape-1.png" alt="Loreto Convent School, Delhi Cantt"/></LazyLoad>
        </div>
        <div className="container">
        <div className="page-header__inner">
            <ul className="thm-breadcrumb list-unstyled ml-0">
                <li><Link to="/"> Home</Link></li>
                <li> Outreach </li>
                <li> Visit to AWWA Asha School </li>
            </ul>
            <h2>Visit to AWWA Asha School  </h2>
        </div>
        </div>
    </section>
    <section className="innerbody">
   <div className="container contact-page">
<div className="row">
      <div className="col-md-12">
      <h1 className="head1">Visit to AWWA Asha School </h1>
      <p>On 12 November 2024 class VII C along with their class teacher, Mrs. Swathy Prasanth visited AWWA Asha School at Delhi Cantt. Mrs. Shally Thakur, the in - charge and the inmates welcomed the students. Loreto students interacted with the special children. Students sang songs and engaged them in games . It was a heart -warming trip that reminded of the goal of inclusion, to spread goodness and love in the world.</p>
      </div>
      </div>
<div className="row align-images g-4">
<div className="col-xl-3 col-lg-4 col-md-6 col-12">
<LazyLoad><img src="https://webapi.entab.info/api/image/LCSD/public/Images/AWWA1.jpg"  className="img-fluid" alt="Loreto Convent School, Delhi Cantt"/></LazyLoad>
</div>
<div className="col-xl-3 col-lg-4 col-md-6 col-12">
<LazyLoad><img src="https://webapi.entab.info/api/image/LCSD/public/Images/AWWA2.jpg"  className="img-fluid" alt="Loreto Convent School, Delhi Cantt"/></LazyLoad>
</div>
<div className="col-xl-3 col-lg-4 col-md-6 col-12">
<LazyLoad><img src="https://webapi.entab.info/api/image/LCSD/public/Images/AWWA3.jpg"  className="img-fluid" alt="Loreto Convent School, Delhi Cantt"/></LazyLoad>
</div>

<div className="col-xl-3 col-lg-4 col-md-6 col-12">
<LazyLoad><img src="https://webapi.entab.info/api/image/LCSD/public/Images/AWWA4.jpg"  className="img-fluid" alt="Loreto Convent School, Delhi Cantt"/></LazyLoad>
</div>

<div className="col-xl-3 col-lg-4 col-md-6 col-12">
<LazyLoad><img src="https://webapi.entab.info/api/image/LCSD/public/Images/AWWA5.jpg"  className="img-fluid" alt="Loreto Convent School, Delhi Cantt"/></LazyLoad>
</div>  

</div>

</div>
</section>
   </>
  )
}

export default VisitAWWAASHA