import React from 'react'
import { Link } from 'react-router-dom'
import {SlideshowLightbox} from 'lightbox.js-react'
import LazyLoad from 'react-lazyload';
const HealthPhysicalEdu = () => {
  return (
    <>
    <section className="page-header">
        <div className="page-header-bg"></div>
        <div className="page-header-shape-1"><LazyLoad><img src="https://webapi.entab.info/api/image/LCSD/public/Images/page-header-shape-1.png" alt="Loreto Convent School, Delhi Cantt"/></LazyLoad></div>
        <div className="container">
        <div className="page-header__inner">
            <ul className="thm-breadcrumb list-unstyled ml-0">
                <li><Link to="/"> Home</Link></li>
                <li> Academics </li>
                <li> Health & Physical Education  </li>
            </ul>
            <h2>Health & Physical Education  </h2>
        </div>
        </div>
    </section>
    <section className="innerbody">
   <div className="container">
       <div className="row">
           <div className="col-lg-12">
             <p>Physical Education is an important part of education. The main aim of mainstreaming of Health & Physical Education is to enable the students to attain an optimum state of health and develop awareness regarding the importance of physical fitness and social life including life skills.</p>
           </div>
           <div className="col-xl-12 pt-3">
           <h1 className="head1">Distribution of Marks for Internal Assessment</h1>

           <div className="table-responsive innertable lefttr">
   <table className="table">
      <tbody>
         <tr>
          <th> S. No. </th>
            <th>Strand</th>
            <th>Marks</th>

         </tr>
         <tr>
         <td>1</td>
            <td>  Games (any  one event/games/sports)<br />
               (a) Athletics/Swimming<br />
               (b) Team Games<br />
               (c) Individual Games & Activities<br />
               (d) Adventure Sports
            </td>
            <td>50 Marks</td>
         </tr>
         <tr>
             <td>2</td>
            <td> Health & Fitness</td>
            <td>25 Marks</td>
         </tr>
         <tr>
           <td>3</td>
            <td>SEWA</td>
            <td>25 Marks</td>
         </tr>
         <tr>
           <td>4</td>
            <td>Health & Activity Card</td>
            <td>No Marks</td>
         </tr>
         <tr>
           <td>5</td>
            <td><b>Total</b></td>
            <td><b>100 Marks</b></td>
         </tr>
      </tbody>
   </table>
</div>
           <div className="accordion" id="accordionExample">
  <div className="accordion-item">
    <h2 className="accordion-header" id="headingOne">
      <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
      Strand 1 Sports & Games
      </button>
    </h2>
    <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
      <div className="accordion-body">
          <div className="flexbox-v zoompic">
            <div>
               <LazyLoad><SlideshowLightbox><img src="https://webapi.entab.info/api/image/LCSD/public/Images/sportsgames1.jpg" alt="Loreto Convent School Delhi Cantt" className="img-fluid"/></SlideshowLightbox></LazyLoad>
            </div>
            <div>
               <LazyLoad><SlideshowLightbox><img src="https://webapi.entab.info/api/image/LCSD/public/Images/sportsgames2.jpg" alt="Loreto Convent School Delhi Cantt" className="img-fluid"/></SlideshowLightbox></LazyLoad>
            </div>
            <div>
               <LazyLoad><SlideshowLightbox><img src="https://webapi.entab.info/api/image/LCSD/public/Images/sportsgames3.jpg" alt="Loreto Convent School Delhi Cantt" className="img-fluid"/></SlideshowLightbox></LazyLoad>
            </div>
            <div>
               <LazyLoad><SlideshowLightbox><img src="https://webapi.entab.info/api/image/LCSD/public/Images/sportsgames4.jpg" alt="Loreto Convent School Delhi Cantt" className="img-fluid"/></SlideshowLightbox></LazyLoad>
            </div>
            <div>
               <LazyLoad><SlideshowLightbox><img src="https://webapi.entab.info/api/image/LCSD/public/Images/sportsgames5.jpg" alt="Loreto Convent School Delhi Cantt" className="img-fluid"/></SlideshowLightbox></LazyLoad>
            </div>
            <div>
               <LazyLoad><SlideshowLightbox><img src="https://webapi.entab.info/api/image/LCSD/public/Images/sportsgames6.jpg" alt="Loreto Convent School Delhi Cantt" className="img-fluid"/></SlideshowLightbox></LazyLoad>
            </div>
            <div>
               <LazyLoad><SlideshowLightbox><img src="https://webapi.entab.info/api/image/LCSD/public/Images/sportsgames7.jpg" alt="Loreto Convent School Delhi Cantt" className="img-fluid"/></SlideshowLightbox></LazyLoad>
            </div>
         </div>
      </div>
    </div>
  </div>
  <div className="accordion-item">
    <h2 className="accordion-header" id="headingTwo">
      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
 Strand 2 Includes Fitness
      </button>
    </h2>
    <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
      <div className="accordion-body">
      </div>
    </div>
  </div>

<div className="accordion-item">
<h2 className="accordion-header" id="headingThree">
<button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">Strand 3 SEWA</button>
</h2>
<div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
<div className="accordion-body">
<p><b>'Receive that you may give'</b></p>
<p> By keeping in mind, the above motto of our school and to fulfill the vision of our foundress Venerable Mary Ward, Loreto Convent School, Delhi has undertaken a programme called JEEVAN REKHA.  </p>
<p> It is a local initiative and our objective with this programme is to aid girl students from the nearby village with their studies.  </p>
<p> Our students stay back after school every day to assist the Jeevan Rekha students with their studies.  </p>
<p> Our aim is to encourage students to inculcate good values, develop compassion and become socially responsible citizens. </p>
 <br />
<div className="flexbox-v zoompic">
            <div>
               <LazyLoad><SlideshowLightbox><img src="https://webapi.entab.info/api/image/LCSD/public/Images/Strand-SEWA1.jpeg" alt="Loreto Convent School Delhi Cantt" className="img-fluid"/></SlideshowLightbox></LazyLoad>
            </div>
            <div>
               <LazyLoad><SlideshowLightbox><img src="https://webapi.entab.info/api/image/LCSD/public/Images/Strand-SEWA2.jpeg" alt="Loreto Convent School Delhi Cantt" className="img-fluid"/></SlideshowLightbox></LazyLoad>
            </div>
            <div>
               <LazyLoad><SlideshowLightbox><img src="https://webapi.entab.info/api/image/LCSD/public/Images/Strand-SEWA3.jpeg" alt="Loreto Convent School Delhi Cantt" className="img-fluid"/></SlideshowLightbox></LazyLoad>
            </div>
            <div>
               <LazyLoad><SlideshowLightbox><img src="https://webapi.entab.info/api/image/LCSD/public/Images/Strand-SEWA4.jpeg" alt="Loreto Convent School Delhi Cantt" className="img-fluid"/></SlideshowLightbox></LazyLoad>
            </div>
            <div>
               <LazyLoad><SlideshowLightbox><img src="https://webapi.entab.info/api/image/LCSD/public/Images/Strand-SEWA5.jpeg" alt="Loreto Convent School Delhi Cantt" className="img-fluid"/></SlideshowLightbox></LazyLoad>
            </div>
</div>
</div>
</div>
</div>

<div className="accordion-item">
    <h2 className="accordion-header" id="headingFour">
      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
   Strand 4 Health & Activity Card
      </button>
    </h2>
    <div id="collapseFour" className="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
      <div className="accordion-body">
      <ul className="lilist">
      <li><Link to="Admin/Uploading/Static/pdf/Health-Activit-Card.pdf" target="_blank">Health & Activity Card</Link></li>
      <li><Link to="Admin/Uploading/Static/pdf/Health-Activit-record.pdf" target="_blank">Health & Activity Record</Link></li>
      <li><Link to="Admin/Uploading/Static/pdf/sewa.pdf" target="_blank">Sewa </Link></li>
      </ul>
      </div>
    </div>
  </div>
</div>
           </div>
       </div>
   </div>
</section>
   </>
  )
}

export default HealthPhysicalEdu