import React, { useState, useEffect } from 'react';
import { TransformWrapper, TransformComponent, useControls } from 'react-zoom-pan-pinch';
import HTMLFlipBook from 'react-pageflip';

const Magazine2022 = () => {
    const [active, setActive] = useState(true);
    const [activebook, setActivebook] = useState(true);
    const [activebook1, setActivebook1] = useState(true);
    const [target, setTarget] = useState(null);

    const handleShowFlip = () => {  
        setActive(true);
        setActivebook(true);
        setActivebook1(false);
    }; 
    const handleShowFlip1 = () => {  
        setActive(true);
        setActivebook(false);
        setActivebook1(true);
    }; 
    useEffect(() => {
        setTimeout(() => {
            setActive(false);
            
        setActivebook(true);
            setActivebook1(true);
            // document.querySelector(".flipOpen").style.display = "none"
        }, 200);
    }, []);

    const handleClose = () => {
        setActive(false); 
        setActivebook(false);
        setActivebook1(false);
    };

    const Controls = () => {
        const { zoomIn, zoomOut, resetTransform } = useControls();
        return (
            <div className="control-btn">
                <div onClick={zoomIn}>+</div>
                <div onClick={zoomOut}>-</div>
                <div onClick={resetTransform}>Reset</div>
            </div>
        );
    };
    return (
        <>
            <div className={`flip-popup active`} style={{ display: active ? 'block' : 'none' }}>
                <div className="closeme" onClick={handleClose}>+</div>

                <div className={`flipOpen activebook`}  style={{ display: activebook ? 'block' : 'none' }}>
                    <HTMLFlipBook
                        width={500}
                        height={680}
                        minWidth={315}
                        maxWidth={1000}
                        minHeight={400}
                        maxHeight={1200}
                        mobileScrollSupport={true}
                        showCover={true}
                        useMouseEvents={true}
                        className="flipPageComponent"
                        onChange={(e) => setTarget(e)}
                        pageBackground="rgb(255, 255, 255)"
                        uncutPages="true"
                    >
                        <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/magazine/atj1.jpg" /></div>
                        <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/magazine/atj2.jpg" /></div>
                        <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/magazine/atj3.jpg" /></div>
                        <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/magazine/atj4.jpg" /></div>
                        <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/magazine/atj5.jpg" /></div>
                        <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/magazine/atj6.jpg" /></div>
                        <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/magazine/atj7.jpg" /></div>
                        <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/magazine/atj8.jpg" /></div>
                        <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/magazine/atj9.jpg" /></div>
                        <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/magazine/atj10.jpg" /></div>
                        <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/magazine/atj11.jpg" /></div>
                        <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/magazine/atj12.jpg" /></div>
                        <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/magazine/atj13.jpg" /></div>
                        <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/magazine/atj14.jpg" /></div>
                        <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/magazine/atj15.jpg" /></div>
                        <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/magazine/atj16.jpg" /></div>
                        <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/magazine/atj17.jpg" /></div>
                        <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/magazine/atj19.jpg" /></div>
                        <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/magazine/atj20.jpg" /></div>
                        <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/magazine/atj21.jpg" /></div>
                        <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/magazine/atj22.jpg" /></div>
                        <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/magazine/atj23.jpg" /></div>
                        <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/magazine/atj24.jpg" /></div>
                        <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/magazine/atj25.jpg" /></div>
                        <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/magazine/atj26.jpg" /></div>
                        <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/magazine/atj27.jpg" /></div>
                        <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/magazine/atj28.jpg" /></div>
                        <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/magazine/atj29.jpg" /></div>
                        <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/magazine/atj30.jpg" /></div>
                        <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/magazine/atj31.jpg" /></div>
                        <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/magazine/atj32.jpg" /></div>
                        <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/magazine/atj34.jpg" /></div>
                        <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/magazine/atj35.jpg" /></div>
                        <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/magazine/atj36.jpg" /></div>
                        <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/magazine/atj37.jpg" /></div>
                        <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/magazine/atj38.jpg" /></div>
                        <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/magazine/atj39.jpg" /></div>
                        <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/magazine/atj40.jpg" /></div>
                        <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/magazine/atj41.jpg" /></div>
                        <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/magazine/atj42.jpg" /></div>
                        <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/magazine/atj43.jpg" /></div>
                        <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/magazine/atj44.jpg" /></div>
                        <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/magazine/atj45.jpg" /></div>
                        <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/magazine/atj46.jpg" /></div>
                        <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/magazine/atj47.jpg" /></div>
                        <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/magazine/atj48.jpg" /></div>
                        <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/magazine/atj49.jpg" /></div>
                        <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/magazine/atj50.jpg" /></div>
                        <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/magazine/atj51.jpg" /></div>
                        <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/magazine/atj52.jpg" /></div>
                        <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/magazine/atj53.jpg" /></div>
                        <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/magazine/atj54.jpg" /></div>
                        <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/magazine/atj55.jpg" /></div>
                        <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/magazine/atj56.jpg" /></div>
                        <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/magazine/atj57.jpg" /></div>
                        <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/magazine/atj58.jpg" /></div>
                        <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/magazine/atj59.jpg" /></div>
                        <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/magazine/atj60.jpg" /></div>
                        <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/magazine/atj61.jpg" /></div>
                        <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/magazine/atj62.jpg" /></div>
                        <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/magazine/atj63.jpg" /></div>
                        <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/magazine/atj64.jpg" /></div>
                        <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/magazine/atj65.jpg" /></div>
                        <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/magazine/atj66.jpg" /></div>
                        <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/magazine/atj67.jpg" /></div>
                        <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/magazine/atj68.jpg" /></div>
                        <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/magazine/atj69.jpg" /></div>
                        <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/magazine/atj70.jpg" /></div>
                        <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/magazine/atj71.jpg" /></div>
                        <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/magazine/atj72.jpg" /></div>
                        <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/magazine/atj73.jpg" /></div>
                        <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/magazine/atj74.jpg" /></div>
                        <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/magazine/atj75.jpg" /></div>
                        <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/magazine/atj76.jpg" /></div>
                        <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/magazine/atj77.jpg" /></div>
                        <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/magazine/atj78.jpg" /></div>
                        <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/magazine/atj79.jpg" /></div>
                        <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/magazine/atj80.jpg" /></div>
                        <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/magazine/atj81.jpg" /></div>
                        <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/magazine/atj82.jpg" /></div>
                        <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/magazine/atj83.jpg" /></div>
                        <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/magazine/atj84.jpg" /></div>
                        <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/magazine/atj86.jpg" /></div>
                        <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/magazine/atj87.jpg" /></div>
                        <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/magazine/atj88.jpg" /></div>
                        <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/magazine/atj89.jpg" /></div>
                        <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/magazine/atj90.jpg" /></div>
                        <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/magazine/atj91.jpg" /></div>
                        <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/magazine/atj92.jpg" /></div>
                        <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/magazine/atj93.jpg" /></div>
                        <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/magazine/atj94.jpg" /></div>
                        <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/magazine/atj95.jpg" /></div>
                        <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/magazine/atj96.jpg" /></div>
                        <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/magazine/atj97.jpg" /></div>
                        <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/magazine/atj98.jpg" /></div>
                        <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/magazine/atj99.jpg" /></div>
                        <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/magazine/atj100.jpg" /></div>
                        <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/magazine/atj101.jpg" /></div>
                        <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/magazine/atj102.jpg" /></div>
                        <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/magazine/atj103.jpg" /></div>
                        <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/magazine/atj104.jpg" /></div>
                        <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/magazine/atj105.jpg" /></div>
                        <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/magazine/atj106.jpg" /></div>
                        <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/magazine/atj107.jpg" /></div>
                        <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/magazine/atj108.jpg" /></div>
                        <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/magazine/atj109.jpg" /></div>
                        <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/magazine/atj110.jpg" /></div>
                        <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/magazine/atj111.jpg" /></div>
                        <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/magazine/atj112.jpg" /></div>
                        <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/magazine/atj113.jpg" /></div>
                        <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/magazine/atj114.jpg" /></div>
                        <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/magazine/atj115.jpg" /></div>
                        <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/magazine/atj116.jpg" /></div>
                        <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/magazine/atj117.jpg" /></div>
                        <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/magazine/atj118.jpg" /></div>
                        <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/magazine/atj119.jpg" /></div>
                        <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/magazine/atj120.jpg" /></div>
                        <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/magazine/atj121.jpg" /></div>
                        <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/magazine/atj122.jpg" /></div>
                        <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/magazine/atj123.jpg" /></div>
                        <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/magazine/atj124.jpg" /></div>
                        <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/magazine/atj125.jpg" /></div>
                        <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/magazine/atj126.jpg" /></div>
                        <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/magazine/atj127.jpg" /></div>
                        <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/magazine/atj128.jpg" /></div>
                        <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/magazine/atj129.jpg" /></div>
                        <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/magazine/atj130.jpg" /></div>
                        <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/magazine/atj131.jpg" /></div>
                        <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/magazine/atj132.jpg" /></div>
                        <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/magazine/atj133.jpg" /></div>
                        <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/magazine/atj134.jpg" /></div>
                        <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/magazine/atj135.jpg" /></div>
                        <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/magazine/atj136.jpg" /></div>
                        <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/magazine/atj137.jpg" /></div>
                        <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/magazine/atj138.jpg" /></div>
                        <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/magazine/atj139.jpg" /></div>
                        <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/magazine/atj140.jpg" /></div>
                        <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/magazine/atj141.jpg" /></div>
                        <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/magazine/atj142.jpg" /></div>
                        <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/magazine/atj143.jpg" /></div>
                        <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/magazine/atj144.jpg" /></div>
                        <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/magazine/atj145.jpg" /></div>
                        <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/magazine/atj146.jpg" /></div>
                        <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/magazine/atj147.jpg" /></div>
                        <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/magazine/atj148.jpg" /></div>
                        <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/magazine/atj149.jpg" /></div>
                        <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/magazine/atj150.jpg" /></div>
                        <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/magazine/atj151.jpg" /></div>
                        <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/magazine/atj152.jpg" /></div>
                        <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/magazine/atj153.jpg" /></div>
                        <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/magazine/atj154.jpg" /></div>
                        <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/magazine/atj155.jpg" /></div>
                        <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/magazine/atj156.jpg" /></div>
                        <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/magazine/atj157.jpg" /></div>
                        <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/magazine/atj158.jpg" /></div>
                        <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/magazine/atj159.jpg" /></div>
                        <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/magazine/atj160.jpg" /></div>
                    </HTMLFlipBook>
                </div>
                <div className={`flipOpen activebook1`}  style={{ display: activebook1 ? 'block' : 'none' }}>
                    <HTMLFlipBook
                        width={500}
                        height={680}
                        minWidth={315}
                        maxWidth={1000}
                        minHeight={400}
                        maxHeight={1200}
                        mobileScrollSupport={true}
                        showCover={true}
                        useMouseEvents={true}
                        className="flipPageComponent"
                        onChange={(e) => setTarget()}
                        pageBackground="rgb(255, 255, 255)"
                        uncutPages="true"
                    >
                        <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/magazine/aatj.jpg" /></div>
                        <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/magazine/aatj2.jpg" /></div>
                        <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/magazine/aatj3.jpg" /></div>
                        <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/magazine/aatj4.jpg" /></div>
                        <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/magazine/aatj5.jpg" /></div>
                        <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/magazine/aatj6.jpg" /></div>
                        <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/magazine/aatj7.jpg" /></div>
                        <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/magazine/aatj8.jpg" /></div>
                        <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/magazine/aatj9.jpg" /></div>
                        <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/magazine/aatj10.jpg" /></div>
                        <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/magazine/aatj11.jpg" /></div>
                        <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/magazine/aatj12.jpg" /></div>
                        <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/magazine/aatj13.jpg" /></div>
                        <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/magazine/aatj14.jpg" /></div>
                        <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/magazine/aatj15.jpg" /></div>
                        <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/magazine/aatj16.jpg" /></div>
                        <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/magazine/aatj17.jpg" /></div>
                        <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/magazine/aatj19.jpg" /></div>
                        <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/magazine/aatj20.jpg" /></div>
                        <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/magazine/aatj21.jpg" /></div>
                        <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/magazine/aatj22.jpg" /></div>
                        <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/magazine/aatj23.jpg" /></div>
                        <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/magazine/aatj24.jpg" /></div>
                        <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/magazine/aatj25.jpg" /></div>
                        <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/magazine/aatj26.jpg" /></div>
                        <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/magazine/aatj27.jpg" /></div>
                        <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/magazine/aatj28.jpg" /></div>
                        <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/magazine/aatj29.jpg" /></div>
                        <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/magazine/aatj30.jpg" /></div>
                        <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/magazine/aatj31.jpg" /></div>
                        <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/magazine/aatj32.jpg" /></div>
                        <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/magazine/aatj34.jpg" /></div>
                        <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/magazine/aatj35.jpg" /></div>
                        <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/magazine/aatj36.jpg" /></div>
                        <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/magazine/aatj37.jpg" /></div>
                        <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/magazine/aatj38.jpg" /></div>
                        <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/magazine/aatj39.jpg" /></div>
                        <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/magazine/aatj40.jpg" /></div>
                        <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/magazine/aatj41.jpg" /></div>
                        <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/magazine/aatj42.jpg" /></div>
                        <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/magazine/aatj43.jpg" /></div>
                        <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/magazine/aatj44.jpg" /></div>
                        <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/magazine/aatj45.jpg" /></div>
                        <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/magazine/aatj46.jpg" /></div>
                        <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/magazine/aatj47.jpg" /></div>
                        <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/magazine/aatj48.jpg" /></div>
                        <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/magazine/aatj49.jpg" /></div>
                        <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/magazine/aatj50.jpg" /></div>
                        <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/magazine/aatj51.jpg" /></div>
                        <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/magazine/aatj52.jpg" /></div>
                        <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/magazine/aatj53.jpg" /></div>
                        <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/magazine/aatj54.jpg" /></div>
                        <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/magazine/aatj55.jpg" /></div>
                        <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/magazine/aatj56.jpg" /></div>
                        <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/magazine/aatj57.jpg" /></div>
                        <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/magazine/aatj58.jpg" /></div>
                        <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/magazine/aatj59.jpg" /></div>
                        <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/magazine/aatj60.jpg" /></div>
                        <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/magazine/aatj61.jpg" /></div>
                        <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/magazine/aatj62.jpg" /></div>
                        <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/magazine/aatj63.jpg" /></div>
                        <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/magazine/aatj64.jpg" /></div>
                        <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/magazine/aatj65.jpg" /></div>
                        <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/magazine/aatj66.jpg" /></div>
                        <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/magazine/aatj67.jpg" /></div>
                        <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/magazine/aatj68.jpg" /></div>
                        <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/magazine/aatj69.jpg" /></div>
                        <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/magazine/aatj70.jpg" /></div>
                        <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/magazine/aatj71.jpg" /></div>
                        <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/magazine/aatj72.jpg" /></div>
                        <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/magazine/aatj73.jpg" /></div>
                        <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/magazine/aatj74.jpg" /></div>
                        <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/magazine/aatj75.jpg" /></div>
                        <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/magazine/aatj76.jpg" /></div>
                        <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/magazine/aatj77.jpg" /></div>
                        <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/magazine/aatj78.jpg" /></div>
                        <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/magazine/aatj79.jpg" /></div>
                        <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/magazine/aatj80.jpg" /></div>
                        <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/magazine/aatj81.jpg" /></div>
                        <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/magazine/aatj82.jpg" /></div>
                        <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/magazine/aatj83.jpg" /></div>
                        <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/magazine/aatj84.jpg" /></div>
                        <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/magazine/aatj86.jpg" /></div>
                        <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/magazine/aatj87.jpg" /></div>
                        <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/magazine/aatj88.jpg" /></div>
                        <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/magazine/aatj89.jpg" /></div>
                        <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/magazine/aatj90.jpg" /></div>
                        <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/magazine/aatj91.jpg" /></div>
                        <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/magazine/aatj92.jpg" /></div>
                        <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/magazine/aatj93.jpg" /></div>
                        <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/magazine/aatj94.jpg" /></div>
                        <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/magazine/aatj95.jpg" /></div>
                        <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/magazine/aatj96.jpg" /></div>
                        <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/magazine/aatj97.jpg" /></div>
                        <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/magazine/aatj98.jpg" /></div>
                        <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/magazine/aatj99.jpg" /></div>
                        <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/magazine/aatj100.jpg" /></div>
                        <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/magazine/aatj101.jpg" /></div>
                        <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/magazine/aatj102.jpg" /></div>
                        <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/magazine/aatj103.jpg" /></div>
                        <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/magazine/aatj104.jpg" /></div>
                        <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/magazine/aatj105.jpg" /></div>
                        <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/magazine/aatj106.jpg" /></div>
                        <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/magazine/aatj107.jpg" /></div>
                        <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/magazine/aatj108.jpg" /></div>
                        <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/magazine/aatj109.jpg" /></div>
                        <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/magazine/aatj110.jpg" /></div>
                        <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/magazine/aatj111.jpg" /></div>
                        <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/magazine/aatj112.jpg" /></div>
                        <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/magazine/aatj113.jpg" /></div>
                        <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/magazine/aatj114.jpg" /></div>
                        <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/magazine/aatj115.jpg" /></div>
                        <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/magazine/aatj116.jpg" /></div>
                        <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/magazine/aatj117.jpg" /></div>
                        <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/magazine/aatj118.jpg" /></div>
                        <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/magazine/aatj119.jpg" /></div>
                        <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/magazine/aatj120.jpg" /></div>
                        <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/magazine/aatj121.jpg" /></div>
                        <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/magazine/aatj122.jpg" /></div>
                        <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/magazine/aatj123.jpg" /></div>
                        <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/magazine/aatj124.jpg" /></div>
                        <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/magazine/aatj125.jpg" /></div>
                        <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/magazine/aatj126.jpg" /></div>
                        <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/magazine/aatj127.jpg" /></div>
                        <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/magazine/aatj128.jpg" /></div> 
                    </HTMLFlipBook>
                </div>

            </div>
            <div className="d-flex flipSelection">
                <div className=" tab-pane active" id="tab1">
                    <div className="flip-block showflip" data-trigger="1" onClick={handleShowFlip}>
                        <h3>AUGUST 2021 - JANUARY 2022</h3>
                        <img src="https://webapi.entab.info/api/image/LCSD/public/Images/magazine/atj1.jpg" alt=""></img>
                    </div>
                    <div className="flip-block  showflip" data-trigger="2" onClick={handleShowFlip1}>
                        <h3>APRIL 2020 - JULY 2021</h3>
                        <img src="https://webapi.entab.info/api/image/LCSD/public/Images/magazine/aatj.jpg" alt=""></img>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Magazine2022