import React from 'react'
import { Link } from 'react-router-dom'
import LazyLoad from 'react-lazyload';
const LoretoDelhiCommunity = () => {
  return (
    <>
    <section className="page-header">
        <div className="page-header-bg"></div>
        <div className="page-header-shape-1"><LazyLoad><img src="https://webapi.entab.info/api/image/LCSD/public/Images/page-header-shape-1.png" alt="Loreto Convent School, Delhi Cantt"/></LazyLoad></div>
        <div className="container">
        <div className="page-header__inner">
            <ul className="thm-breadcrumb list-unstyled ml-0">
                <li><Link to="/"> Home</Link></li>
                <li>  Office Bearers </li>
                <li> Loreto Delhi Community  </li>
            </ul>
            <h2>Loreto Delhi Community </h2>
        </div>
        </div>
    </section>
    <section className="innerbody">
   <div className="container">
       <div className="row">
        <div className="col-md-12">
        <table className="table table-striped table-bordered">
<tbody><tr>
                 <th colspan="3" style={{textAlign: "center"}}>

                     <strong>LORETO CONVENT SCHOOL </strong>

                 </th>
             </tr>
             <tr>
             <td><b>S. No.</b> </td>
                 <td><b>Name</b> </td>
                 <td><b>Designation</b> </td>               
             </tr>


             <tr><td>1</td><td>Sr. Igora Pinto</td><td> Secretary  </td></tr>
             <tr><td>2</td><td>Sr. Mariana Bage</td><td> Community Member</td></tr>
             <tr><td>3 </td><td>Sr. Greta Robert</td><td> Community Member</td></tr>
             <tr><td>4</td><td>Sr. Arockia Mary Dass</td><td> Community Member</td></tr>
             <tr><td>5</td><td>Sr. Aruna Kujur</td><td> Community Member</td></tr>






 </tbody>
</table>
        
        </div>  
       </div>
   </div>
</section>
   </>
  )
}

export default LoretoDelhiCommunity