import React from 'react'
import HomeSlider from'../Component/HomeSlider'
import HomeNews from'../Component/HomeNews'
import HomeMessage from'../Component/HomeMessage'
import HomeEvent from'../Component/HomeEvent'
import HomeTopperXII from'../Component/HomeTopperXII'
import HomeTopperX from'../Component/HomeTopperX'
import HomeGallery from'../Component/HomeGallery'
import HomeBirthday from'../Component/HomeBirthday'
import Calender from'../Component/Calender'
import { Link } from 'react-router-dom'
import ParentPortal from'../Component/ParentPortal'
import HomeActivities from'../Component/HomeActivities'
import KidsCorner from'../Component/KidsCorner'
import ModalPopup from'../Component/ModalPopup'
import LazyLoad from 'react-lazyload';
const Home = () => {
  return (
    <>
     <ModalPopup/>
    <div className="rightimg">  <Link to="JpicPage"><LazyLoad><img src="https://webapi.entab.info/api/image/LCSD/public/Images/jpic-logo.jpg" alt="Loreto Convent School Delhi Cantt" className="img-fluid"/></LazyLoad></Link> </div>
    <ParentPortal/>
    <HomeSlider/>
 
    <div className="midsec">
       <div className="midsecbox1">
          <Link to="CBSEDisclosure" className="midsecboxsec">
              <div className="midsecboxsecimg">
                  <LazyLoad><img src="https://webapi.entab.info/api/image/LCSD/public/Images/Disclosureimg.png" className="img-fluid" alt="Loreto Convent School Delhi Cantt"/></LazyLoad>
              </div>
              <div className="midsecboxsecdesc">
                 <p>CBSE Disclosure</p>
              </div>
          </Link>
          <Link to="https://smarthubeducation.hdfcbank.com/SmartFees/Landing.action?instId=LED" target="_blank" className="midsecboxsec yel">
              <div className="midsecboxsecimg">
                  <LazyLoad><img src="https://webapi.entab.info/api/image/LCSD/public/Images/onlinepaymentimg.png" className="img-fluid" alt="Loreto Convent School Delhi Cantt"/></LazyLoad>
              </div>
              <div className="midsecboxsecdesc">
                 <p>online payment</p>
              </div>
          </Link>
          <Link to="Pphelp" className="midsecboxsec">
              <div className="midsecboxsecimg">
                  <LazyLoad><img src="https://webapi.entab.info/api/image/LCSD/public/Images/Portalimg.png" className="img-fluid" alt="Loreto Convent School Delhi Cantt"/></LazyLoad>
              </div>
              <div className="midsecboxsecdesc">
                 <p>Portal (Help?)</p>
              </div>
          </Link>
       </div>
       <div className="midsecbox2">
              <Link to="ContactUs" className="midsecboxdiv">
              <div className="midsecboxdivcircle"><LazyLoad><img src="https://webapi.entab.info/api/image/LCSD/public/Images/contactimg.png" className="img-fluid" alt="Loreto Convent School Delhi Cantt"/></LazyLoad> </div>
              </Link>
       </div>
       <div className="midsecbox1">
   <Link to="https://forms.gle/3xqxptcdSYmwCjNZA" target="_blank" className="midsecboxsec yel">
              <div className="midsecboxsecimg">
                  <LazyLoad><img src="https://webapi.entab.info/api/image/LCSD/public/Images/Alumniimg.png" className="img-fluid" alt="Loreto Convent School Delhi Cantt"/></LazyLoad>
              </div>
              <div className="midsecboxsecdesc">
                 <p>AlumnAE registration</p>
              </div>
          </Link>
          <Link to="https://webapi.entab.info/api/image/LCSD/public/pdf/BookList.pdf" target="_blank" className="midsecboxsec">
              <div className="midsecboxsecimg">
                  <LazyLoad><img src="https://webapi.entab.info/api/image/LCSD/public/Images/Booklistimg.png" className="img-fluid" alt="Loreto Convent School Delhi Cantt"/></LazyLoad>
              </div>
              <div className="midsecboxsecdesc">
                 <p>Book list</p>
              </div>
          </Link>
          <Link to="https://webapi.entab.info/api/image/LCSD/public/pdf/bus-routes.pdf" target="_blank" className="midsecboxsec">
          <div className="midsecboxsecimg">
              <LazyLoad><img src="https://webapi.entab.info/api/image/LCSD/public/Images/Busroute.png" className="img-fluid" alt="Loreto Convent School Delhi Cantt"/></LazyLoad>
          </div>
          <div className="midsecboxsecdesc">
             <p>Bus route</p>
          </div>
      </Link>
       </div>
    </div>
    
   <div className="newnews">
        <div className="container-fluid">
             <div className="row">
                 <div className="col-lg-5 col-md-12">
                    <div className="newnewsgr">
                    <div className="section-title-two text-left">
                    <div className="section-title-two__sub-title-box">
                       <p className="section-title-two__sub-title"> <span className="section-title-two__slash-one">//</span>
                         KEY DATES AND SCHOOL HAPPENINGS<span className="section-title-two__slash-two">\\</span>
                       </p>
                    </div>
                    <h2 className="section-title section-title-two__title">Our School Bulletin</h2>
                 </div> 
                   <HomeNews/>
                    </div>
                <div className="why-choose-three__btn-box">
                     <Link to="News" className="thm-btn"> View More </Link>
                 </div>
                 </div>
                 <div className="col-lg-6 col-md-12">
                 
                 </div>
             </div>
        </div>
   
   </div>
 
    <section className="about-sec">
<div className="container-fluid fullwdh">
      <div className="row">
         <div className="col-xl-6 col-lg-6 col-md-12">
            <LazyLoad><img src="https://webapi.entab.info/api/image/LCSD/public/Images/aboutimg.png" className="img-fluid" alt="Loreto Convent School Delhi Cantt"/></LazyLoad>
         </div>
         <div className="col-xl-6 col-lg-6 col-md-12">
            <div className="about-sec__right">
               <div className="section-title text-left">
                  <div className="section-title__sub-title-box">
                     <p className="section-title__sub-title text-left"> <span className="section-title__slash-one">//</span>About School <span className="section-title__slash-two">\\</span>
                     </p>
                  </div>
                  <h2 className="section-title">LORETO CONVENT SCHOOL, <span>DELHI CANTT</span></h2> 
               </div>
               <div className="aboutsecdesc">
               <p><b>Loreto Convent School </b>is an unaided minority Christian School owned and  managed by The Loreto Educational Society (Loreto nuns) registered under the <b>Societies Registration Act 1860. </b>The school is affiliated to the Central Board of Secondary Education <b>(Affiliation No. 2730135)</b>. The school has classes from Prep to XII. <br/>
               The school is meant primarily for Catholics and the children of Defence Personnel. Civilians are also admitted. <br/>
               The aim of the school is to give its pupils a sound moral education while devoting special attention to their spiritual, intellectual, social  and physical development.</p>
               </div>
               
               <br />
              <div className="about-sec__btn-box d-block mx-auto">
              <Link to="OurSchool" className="thm-btn about-sec__btn">Discover More</Link> 
              
             </div>
             <div className="aboutaudio">
                <p>Loreto Chorus</p>
                <div className="aboutaudiobox">
                     <audio controls><source src="https://webapi.entab.info/api/image/LCSD/public/Images/Chorusa.mp3" type="audio/mpeg"/></audio>
                     <button>Loreto Chorus with Lyrics</button>
                </div>
             </div>
            </div>
         </div>
      </div>
   </div>
</section>
<div className="elementor-widget-wrap">
<div className="elementor-element">
   <div className="elementor-widget-container">
      <section className="feature">
         <div className="container">
            <ul className="list-unstyled feature__list ml-0">
            <li>
            <Link to="MissionStatement">
             <div className="feature__single">
                <div className="feature__icon insur-icon-svg">
                   <span aria-hidden="true" className="  insur-three-icon-insurance"><LazyLoad><img src="https://webapi.entab.info/api/image/LCSD/public/Images/mission.png" className="img-fluid" alt="Loreto Convent School Delhi Cantt"/></LazyLoad></span>                    
                </div>
                <h3 className="feature__title">
                  MISSION STATEMENT
                </h3>
                <p className="feature__text">We are called as a Loreto School to promote the Glory of God, lovingly discerning God’s will for us in a growing freedom, sincerity and justice.</p>
             </div>
             </Link>
          </li>
          <li>
           <Link to="SchoolMotto">
             <div className="feature__single">
                <div className="feature__icon insur-icon-svg">
                   <span aria-hidden="true" className="   icon-cashback"><LazyLoad><img src="https://webapi.entab.info/api/image/LCSD/public/Images/motto.png" className="img-fluid" alt="Loreto Convent School Delhi Cantt"/></LazyLoad></span>                   
                </div>
                <h3 className="feature__title">
                SCHOOL MOTTO
                </h3>
                <p className="feature__text">Receive that you may give</p>
             </div>
              </Link>
          </li>
          <li>
               <Link to="JourneyLoreto">
             <div className="feature__single">
                <div className="feature__icon insur-icon-svg">
                   <span aria-hidden="true" className="   icon-house"><LazyLoad><img src="https://webapi.entab.info/api/image/LCSD/public/Images/sports.png" className="img-fluid" alt="Loreto Convent School Delhi Cantt"/></LazyLoad></span>                     
                </div>
                <h3 className="feature__title">
               JOURNEY OF LORETO 
                </h3>
                <p className="feature__text">MARY WARD (1585 - 1645) was the foundress of the institute of the Blessed Virgin Mary, a religious order which is now spread all over the world and which engages mainly in the education of girls. </p>
             </div>
              </Link>
          </li>
            </ul>
         </div>
      </section>
      
   </div>
</div>
</div>
<div className="kidcorner">
<div className="container-fluid fulwdh">
<div className="row">
 <div className="col-md-6 col-xl-4 col-lg-5"> 
      <Calender/>
 </div>
 <div className="col-md-6 col-xl-8  col-lg-7 kinderdiv"> 
 <div className="section-title title-style-two text-center mb-40 tg-heading-subheading animation-style2">
 <center>
    <div className="section-title">
       <div className="section-title__sub-title-box">
          <p className="section-title__sub-title"> 
             <span className="section-title__slash-one text-left">//</span>Kid's Corner<span className="section-title__slash-two">\\</span> 
          </p>
       </div>
       <h2 className="section-title"> Creative canvas</h2>
    </div>
 </center>
</div> 
 <div className="kinder">
     
      <div className="kinderbox">
         <KidsCorner/>
         </div>
         </div>
     <div className="about-sec__btn-box">
        <Link to="KidsCorner" className="thm-btn about-sec__btn m-auto">View All</Link>
     </div>
    
 </div>
</div>
   </div>

</div>
<div className="activites">
    <div className="container-fluid activitesfulwdh">
          <div className="activitesbg">
               
                  <div className="actititle">
                        <div className="activitestitle">
                              <p>some memorable moments</p>
                              <h2>Recent activities in the school</h2>
                             <Link className="thm-btn about-sec__btn" to="RecentActivity">View More</Link>
                        </div>
                  </div>
                  <div className="activitiesslide">
                      <HomeActivities/>
                  </div>
            </div>
     </div>
</div>
    <HomeMessage/>
    <section className="event-sec">
<div className="services-four__bg"  style={{backgroundImage:"url(https://webapi.entab.info/api/image/LCSD/public/Images/event-bg.jpg)"}}></div>
<div className="container">
<div className="row">
<div className="col-md-12">
<center>
<div className="section-title"> 
<div className="section-title__sub-title-box"> 
<p className="section-title__sub-title"> 
<span className="section-title__slash-one">//</span> What's Happening at Our School<span className="section-title__slash-two">\\</span> </p> 
</div>
<h2 className="section-title">EVENTS UPDATE</h2> 
</div>
</center>
</div>
<div className="col-md-12">
   <HomeEvent/>
</div>
<center>
<div className="about-sec__btn-box">
 <Link to="Events" className="thm-btn about-sec__btn">Read More </Link>
</div>
</center>
</div>
</div>
</section>
    <section className="bg-silver-light z-index-1">
<div className="services-four__bg"  style={{backgroundImage:" url(/Images/event-bg.jpg)"}}></div>
<div className="container">
<div className="section-content">
      <div className="row g-3">

         <div className="col-xl-4 col-lg-6 col-md-12 col-sm-12 col-12">
            <div className="row">
               <div className="col-lg-12 col-md-12">
                  <div className="text-btn">
                     <h1 className="head2 topper-head">Class X</h1>
                      <Link to="TopperX" className="link-more">View All</Link>
                  </div>
               </div>
            </div>
            <div className="row">
               <div className="col-md-12">
                   <HomeTopperX/>
               </div>
            </div>
         </div>
  <div className="col-xl-4 col-lg-6 col-md-12 col-sm-12 col-12">
            <div className="row">
               <div className="col-lg-12 col-md-12">
                  <div className="text-btn">
                     <h1 className="head2">Happy Birthday</h1>
                  </div>
               </div>
            </div>
            <div className="row">
               <div className="col-md-12">
                    <HomeBirthday/>
               </div>
            </div>
         </div>


        <div className="col-xl-4 col-lg-12 col-md-12 col-sm-12 col-12">
            <div className="row">
               <div className="col-lg-12 col-md-12">
                  <div className="text-btn">
                     <h1 className="head2 topper-head">class XII</h1>
                      <Link to="TopperXII" className="link-more">View All</Link>
                  </div>
               </div>
            </div>
            <div className="row">
               <div className="col-md-12">
                   <HomeTopperXII/>
               </div>
            </div>
         </div>



      </div>
   </div>

 </div>
</section>
    <section className="project-area fix">
   <div className="project-bg"  style={{backgroundImage:" url(/Images/event-bg.jpg)"}}></div>
   <div className="container custom-container-two">
   <div className="row justify-content-center">
      <div className="col-xl-6">
         <div className="section-title title-style-two text-center mb-40 tg-heading-subheading animation-style2">
            <center>
               <div className="section-title">
                  <div className="section-title__sub-title-box">
                     <p className="section-title__sub-title"> 
                        <span className="section-title__slash-one ">//</span>SOME MEMORABLE MOMENTS<span className="section-title__slash-two">\\</span> 
                     </p>
                  </div>
                  <h2 className="section-title"> Photo Gallery</h2>
               </div>
            </center>
         </div>
      </div>
   </div>
   <div className="row">
      <div className="col-md-12">
         <HomeGallery/>
      </div>
      </div>
      <center>
<div className="about-sec__btn-box">
 <Link to="Gallery" className="thm-btn about-sec__btn">View Gallery</Link>
</div>
</center>
   </div>
</section>
    <section className="newsletter-area pb-20">
    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="newsletter-inner">
                                    <div className="newsletter-bg">
                                        <LazyLoad><img src="https://webapi.entab.info/api/image/LCSD/public/Images/newsletter_shape.png" className="img-fluid"/></LazyLoad>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="1170" height="241.22" viewBox="0 0 1170 241.22">
                                            <path d="M410.108,7586.87l1117.132,68.34c9.84,0.6,17.76,10.69,17.76,22.55v128.78c0,11.85-7.92,21.46-17.76,21.46H410.108C390.8,7828,375,7814.38,375,7797.59V7615.13C375,7598.34,390.8,7585.69,410.108,7586.87Z" transform="translate(-375 -7586.78)"></path>
                                        </svg>
                                    </div>
                                    <div className="row align-items-center">
                                        <div className="col-lg-7">
                                            <div className="newsletter-content">
                                                <h2 className="title">Discovering knowledge, fostering creativity, and inspiring students to pursue their passions.</h2>
                                            </div>
                                        </div>
                                        <div className="col-lg-5">
                                            <div className="newsletter-contact">
                                                <div className="icon">
                                                   <i className="bi bi-telephone"></i>
                                                </div>
                                                <div className="content">
                                                    <span>REACH OUT NOW!</span>
                                                     <Link to="tel:01125692299">011-25692299</Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
    </section>
    </>
       
  )
}

export default Home
