import React from 'react'
import { Link } from 'react-router-dom'
import LazyLoad from 'react-lazyload';
const EvaluationAssessment = () => {
  return (
    <>
    <section className="page-header">
        <div className="page-header-bg"></div>
        <div className="page-header-shape-1"><LazyLoad><img src="https://webapi.entab.info/api/image/LCSD/public/Images/page-header-shape-1.png" alt="Loreto Convent School, Delhi Cantt"/></LazyLoad></div>
        <div className="container">
        <div className="page-header__inner">
            <ul className="thm-breadcrumb list-unstyled ml-0">
                <li><Link to="/"> Home</Link></li>
                <li> Prospectus </li>
                <li> Evaluation Assessment  </li>
            </ul>
            <h2>Evaluation Assessment </h2>
        </div>
        </div>
    </section>
    <section className="innerbody">
<div className="container">
<div className="row">
<div className="col-md-12">
<div id="carouselExampleControlsNoTouching" className="carousel slide" data-bs-touch="false" data-bs-interval="false">
  <div className="carousel-inner">
    <div className="carousel-item active">
     <img src="https://webapi.entab.info/api/image/LCSD/public/Images/evaluation-1.jpg" className="d-block w-100" alt="Loreto Convent School, Delhi Cantt"/>
    </div>
    <div className="carousel-item">
      <img src="https://webapi.entab.info/api/image/LCSD/public/Images/evaluation-2.jpg" className="d-block w-100" alt="Loreto Convent School, Delhi Cantt"/>
    </div>
    <div className="carousel-item">
      <img src="https://webapi.entab.info/api/image/LCSD/public/Images/evaluation-3.jpg" className="d-block w-100" alt="Loreto Convent School, Delhi Cantt"/>
    </div>
    <div className="carousel-item">
      <img src="https://webapi.entab.info/api/image/LCSD/public/Images/evaluation-4.jpg" className="d-block w-100" alt="Loreto Convent School, Delhi Cantt"/>
    </div>
    <div className="carousel-item">
      <img src="https://webapi.entab.info/api/image/LCSD/public/Images/evaluation-5.jpg" className="d-block w-100" alt="Loreto Convent School, Delhi Cantt"/>
    </div>

        <div className="carousel-item">
      <img src="https://webapi.entab.info/api/image/LCSD/public/Images/evaluation-6.jpg" className="d-block w-100" alt="Loreto Convent School, Delhi Cantt"/>
    </div>
    <div className="carousel-item">
      <img src="https://webapi.entab.info/api/image/LCSD/public/Images/evaluation-7.jpg" className="d-block w-100" alt="Loreto Convent School, Delhi Cantt"/>
    </div>
    <div className="carousel-item">
      <img src="https://webapi.entab.info/api/image/LCSD/public/Images/evaluation-8.jpg" className="d-block w-100" alt="Loreto Convent School, Delhi Cantt"/>
    </div>
    <div className="carousel-item">
      <img src="https://webapi.entab.info/api/image/LCSD/public/Images/evaluation-9.jpg" className="d-block w-100" alt="Loreto Convent School, Delhi Cantt"/>
    </div>
        <div className="carousel-item">
      <img src="https://webapi.entab.info/api/image/LCSD/public/Images/evaluation-10.jpg" className="d-block w-100" alt="Loreto Convent School, Delhi Cantt"/>
    </div>
    <div className="carousel-item">
      <img src="https://webapi.entab.info/api/image/LCSD/public/Images/evaluation-11.jpg" className="d-block w-100" alt="Loreto Convent School, Delhi Cantt"/>
    </div>
    <div className="carousel-item">
      <img src="https://webapi.entab.info/api/image/LCSD/public/Images/evaluation-12.jpg" className="d-block w-100" alt="Loreto Convent School, Delhi Cantt"/>
    </div>
    <div className="carousel-item">
      <img src="https://webapi.entab.info/api/image/LCSD/public/Images/evaluation-13.jpg" className="d-block w-100" alt="Loreto Convent School, Delhi Cantt"/>
    </div>

        <div className="carousel-item">
      <img src="https://webapi.entab.info/api/image/LCSD/public/Images/evaluation-14.jpg" className="d-block w-100" alt="Loreto Convent School, Delhi Cantt"/>
    </div>
    <div className="carousel-item">
      <img src="https://webapi.entab.info/api/image/LCSD/public/Images/evaluation-15.jpg" className="d-block w-100" alt="Loreto Convent School, Delhi Cantt"/>
    </div>
    <div className="carousel-item">
      <img src="https://webapi.entab.info/api/image/LCSD/public/Images/evaluation-16.jpg" className="d-block w-100" alt="Loreto Convent School, Delhi Cantt"/>
    </div>
    <div className="carousel-item">
      <img src="https://webapi.entab.info/api/image/LCSD/public/Images/evaluation-17.jpg" className="d-block w-100" alt="Loreto Convent School, Delhi Cantt"/>
    </div>

        <div className="carousel-item">
      <img src="https://webapi.entab.info/api/image/LCSD/public/Images/evaluation-18.jpg" className="d-block w-100" alt="Loreto Convent School, Delhi Cantt"/>
    </div>
    <div className="carousel-item">
      <img src="https://webapi.entab.info/api/image/LCSD/public/Images/evaluation-19.jpg" className="d-block w-100" alt="Loreto Convent School, Delhi Cantt"/>
    </div>
    <div className="carousel-item">
      <img src="https://webapi.entab.info/api/image/LCSD/public/Images/evaluation-20.jpg" className="d-block w-100" alt="Loreto Convent School, Delhi Cantt"/>
    </div>
    <div className="carousel-item">
      <img src="https://webapi.entab.info/api/image/LCSD/public/Images/evaluation-21.jpg" className="d-block w-100" alt="Loreto Convent School, Delhi Cantt"/>
    </div>
<div className="carousel-item">
      <img src="https://webapi.entab.info/api/image/LCSD/public/Images/evaluation-22.jpg" className="d-block w-100" alt="Loreto Convent School, Delhi Cantt"/>
    </div>
    <div className="carousel-item">
      <img src="https://webapi.entab.info/api/image/LCSD/public/Images/evaluation-23.jpg" className="d-block w-100" alt="Loreto Convent School, Delhi Cantt"/>
    </div>
</div>
  <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleControlsNoTouching" data-bs-slide="prev">
    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
    <span className="visually-hidden">Previous</span>
  </button>
  <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleControlsNoTouching" data-bs-slide="next">
    <span className="carousel-control-next-icon" aria-hidden="true"></span>
    <span className="visually-hidden">Next</span>
  </button>
</div>


</div>


</div>
</div>
</section>
   </>
  )
}

export default EvaluationAssessment