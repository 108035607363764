import React  from "react"; 
import LazyLoad from 'react-lazyload';
import { Link } from 'react-router-dom' 
const ValueEducation = () => {
            
  return (
    <>
    <section className="page-header">
        <div className="page-header-bg"></div>
        <div className="page-header-shape-1"><LazyLoad><img src="https://webapi.entab.info/api/image/LCSD/public/Images/page-header-shape-1.png" alt="Loreto Convent School, Delhi Cantt"/></LazyLoad></div>
        <div className="container">
        <div className="page-header__inner">
            <ul className="thm-breadcrumb list-unstyled ml-0">
                <li><Link to="/"> Home</Link></li>
                <li> Academics  </li>
                <li> Value Education</li>
            </ul>
            <h2>Value Education </h2>
        </div>
        </div>
    </section>
    <section className="innerbody">
<div className="container">
   <div className="row">
      <div className="col-md-12">
  <div className="video_blk">
  <video width="100%" muted playsInline loop controls>
        <source src="https://webapi.entab.info/api/image/LCSD/public/Images/value-education.mp4" type="video/mp4" />
        Your browser does not support the video tag.
      </video>
  </div>
          
      </div>
   </div>
</div>
</section>
   </>
  )
}

export default ValueEducation