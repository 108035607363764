import React from 'react'
import { Link } from 'react-router-dom'
import LazyLoad from 'react-lazyload';
const EarthquakeTurkish = () => {
  return (
    <>
    <section className="page-header">
        <div className="page-header-bg"></div>
        <div className="page-header-shape-1"><LazyLoad><img src="https://webapi.entab.info/api/image/LCSD/public/Images/page-header-shape-1.png" alt="Loreto Convent School, Delhi Cantt"/></LazyLoad></div>
        <div className="container">
        <div className="page-header__inner">
            <ul className="thm-breadcrumb list-unstyled ml-0">
                <li><Link to="/"> Home</Link></li>
                <li> Outreach </li>
                <li> Earthquake Relief Donations to the Turkish Embassy  </li>
            </ul>
            <h2>Earthquake Relief Donations to the Turkish Embassy </h2>
        </div>
        </div>
    </section>
    <section className="innerbody">
   <div className="container contact-page">
<div className="row"><div className="col-md-12"><h1 className="head1">Earthquake Relief Donations to the Turkish Embassy</h1>

<p> On 17th of February 2023, 7 students from class 11 along with Sr Monica and the JPIC teachers Ms. Mamgain and Ms. Jaisika had visited the Embassy of Turkey to deliver the donations collected for earthquake relief. The Turkish embassy officials were grateful for the efforts made by our students and the parents. The donations consisted of woollen clothes, bed sheets, diapers etc. The smiles on the faces of the embassy personnel reflected  immense gratitude. </p>
</div></div>
<div className="row align-images g-4">
<div className="col-xl-4 col-lg-4 col-md-6 col-12">
<LazyLoad><img src="https://webapi.entab.info/api/image/LCSD/public/Images/TURKEY-1.jpeg"  className="img-fluid" alt="Loreto Convent School, Delhi Cantt"/></LazyLoad>
</div>
<div className="col-xl-4 col-lg-4 col-md-6 col-12">
<LazyLoad><img src="https://webapi.entab.info/api/image/LCSD/public/Images/TURKEY-2.jpeg"  className="img-fluid" alt="Loreto Convent School, Delhi Cantt"/></LazyLoad>
</div>
</div>
</div>
</section>
   </>
  )
}

export default EarthquakeTurkish