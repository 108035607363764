import React from 'react'
import { Link } from 'react-router-dom'
import LazyLoad from 'react-lazyload';
const SchoolHours = () => {
  return (
    <>
    <section className="page-header">
        <div className="page-header-bg"></div>
        <div className="page-header-shape-1"><LazyLoad><img src="https://webapi.entab.info/api/image/LCSD/public/Images/page-header-shape-1.png" alt="Loreto Convent School, Delhi Cantt"/></LazyLoad></div>
        <div className="container">
        <div className="page-header__inner">
            <ul className="thm-breadcrumb list-unstyled ml-0">
                <li><Link to="/"> Home</Link></li>
                <li> Prospectus </li>
                <li> School Hours  </li>
            </ul>
            <h2>School Hours </h2>
        </div>
        </div>
    </section>
    <section className="innerbody">
<div className="container">
<div className="row">
<div className="col-md-12">
<LazyLoad><img src="https://webapi.entab.info/api/image/LCSD/public/Images/school-hours.jpg" className="img-fluid mx-auto d-block imgspace" alt="Loreto Convent School, Delhi Cantt"/>	</LazyLoad>
<h1 className="head1 text-center"><b>SCHOOL TIMING</b></h1>

<p className="text-center">
<b>Summer :</b> 7:00 a.m. - 1:00 p.m.<br />
<b>Winter :</b> 7:30 a.m. - 1:30 p.m.
</p>

</div>
</div>
</div>
</section>
   </>
  )
}

export default SchoolHours