import React from 'react'
import { Link } from 'react-router-dom'
import LazyLoad from 'react-lazyload';
const ListofSubject = () => {
  return (
    <>
    <section className="page-header">
        <div className="page-header-bg"></div>
        <div className="page-header-shape-1"><LazyLoad><img src="https://webapi.entab.info/api/image/LCSD/public/Images/page-header-shape-1.png" alt="Loreto Convent School, Delhi Cantt"/></LazyLoad></div>
        <div className="container">
        <div className="page-header__inner">
            <ul className="thm-breadcrumb list-unstyled ml-0">
                <li><Link to="/"> Home</Link></li>
                <li> Founder </li>
                <li> List of Subject offered  </li>
            </ul>
            <h2>List of Subject offered</h2>
        </div>
        </div>
    </section>
    <section className="innerbody">
<div className="container">
<div className="row">
<div className="col-lg-6 col-md-6 col-12">
<h1 className="head1">JUNIOR SCHOOL</h1>
<table className="table table-striped table-bordered">
<tbody>
         <tr>
             <th>CLASS 5</th>
         </tr>
         <tr>
             <td>ENGLISH</td>
         </tr>
         <tr>
             <td> HINDI </td>
         </tr>
         <tr>
             <td> MATHEMATICS</td>
         </tr>
         <tr>
             <td>SOCIAL SCIENCE </td>
         </tr>
         <tr>
             <td> SCIENCE </td>
         </tr>
           <tr>
             <td> - </td>
         </tr>
     </tbody>
</table>
</div>
<div className="col-lg-6 col-md-6 col-12">
<h1 className="head1">MIDDLE SCHOOL</h1>
<table className="table table-striped table-bordered">
<tbody>
        <tr>
            <th>CLASS 6 TO 8</th>
        </tr>
        <tr>
            <td>ENGLISH</td>
        </tr>
        <tr>
            <td> HINDI </td>
        </tr>
        <tr>
            <td> MATHEMATICS</td>
        </tr>
        <tr>
            <td>SOCIAL SCIENCE </td>
        </tr>
        <tr>
            <td> SCIENCE </td>
        </tr>
        <tr>
            <td>SANSKRIT </td>
        </tr>
    </tbody>
</table>
</div>
</div>
<br />
<div className="row">
<div className="col-md-12"><h1 className="head1">SENIOR SCHOOL</h1></div>
<div className="col-lg-6 col-md-6 col-12">

<table className="table table-striped table-bordered">
<tbody>
        <tr>
            <th>CLASS IX</th>
        </tr>
        <tr>
            <td>ENGLISH</td>
        </tr>
        <tr>
            <td> HINDI </td>
        </tr>
        <tr>
            <td> MATHEMATICS</td>
        </tr>
        <tr>
            <td>SOCIAL SCIENCE </td>
        </tr>
        <tr>
            <td> SCIENCE </td>
        </tr>
        
         <tr>
            <td>ARTIFICIAL INTELLIGENCE </td>
        </tr>
    </tbody>
</table>
</div>
<div className="col-lg-6 col-md-6 col-12">
<table className="table table-striped table-bordered">
<tbody>
        <tr>
            <th>CLASS X</th>
        </tr>
        <tr>
            <td>ENGLISH</td>
        </tr>
        <tr>
            <td> HINDI </td>
        </tr>
        <tr>
            <td> MATHEMATICS</td>
        </tr>
        <tr>
            <td>SOCIAL SCIENCE </td>
        </tr>
        <tr>
            <td> SCIENCE </td>
        </tr>
      
         <tr>
            <td>ARTIFICIAL INTELLIGENCE </td>
        </tr>
    </tbody>
</table>
</div>
</div>
<br />
<div className="row">
<div className="col-lg-6 col-md-6 col-12">
<h1 className="head1">CLASS XI & XII (SCIENCE)</h1>
<ul className="lilist">
<li> ENGLISH- COMPULSORY</li>
<li>PHYSICS- COMPULSORY</li>
<li> CHEMISTRY- COMPULSORY</li>
<li> BIOLOGY \ COMPUTER SCIENCE \ ECONOMICS (ANY 1)</li>
<li> MATHEMATICS \ PSYCHOLOGY \ INFORMATICS PRACTICES (ANY 1)</li>
<li> PHYSICAL EDUCATION- COMPULSORY</li>
</ul>
</div>
<div className="col-lg-6 col-md-6 col-12">
<h1 className="head1">CLASS XI (COMMERCE)</h1>
<ul className="lilist">
<li>ENGLISH- COMPULSORY</li>
<li> ACCOUNTANCY- COMPULSORY</li>
<li>BUSINESS STUDIES - COMPULSORY</li>
<li> ECONOMICS \ COMPUTER SCIENCE (CHOOSE ANY 1)</li>
<li> MATHEMATICS \ PSYCHOLOGY \ INFORMATICS PRACTICES (CHOOSE ANY 1)</li>
<li>PHYSICAL EDUCATION- COMPULSORY</li>
</ul>
</div>
<div className="col-lg-6 col-md-6 col-12">
<h1 className="head1">CLASS XII (COMMERCE)</h1>
<ul className="lilist">
<li>ENGLISH- COMPULSORY</li>
<li>ACCOUNTANCY- COMPULSORY</li>
<li>BUSINESS STUDIES - COMPULSORY</li>
<li>ECONOMICS- COMPULSORY</li>
<li>MATHEMATICS \ PSYCHOLOGY \ INFORMATICS PRACTICES (CHOOSE ANY 1)</li>
<li>PHYSICAL EDUCATION- COMPULSORY</li>
</ul>
</div>
<div className="col-lg-6 col-md-6 col-12">
<h1 className="head1">CLASS XI & XII (HUMANITIES)</h1>
<ul className="lilist">
<li> ENGLISH- COMPULSORY</li>
<li>HISTORY- COMPULSORY</li>
<li> POLITICAL SCIENCE- COMPULSORY</li>
<li>SOCIOLOGY \ ECONOMICS (CHOOSE ANY 1)</li>
<li> MATHEMATICS \ PSYCHOLOGY \ INFORMATICS PRACTICES (CHOOSE ANY 1)</li>
<li>PHYSICAL EDUCATION- COMPULSORY</li>
</ul>
</div>
</div>
</div>
</section>
   </>
  )
}

export default ListofSubject