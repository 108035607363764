import React from 'react'
import { Link } from 'react-router-dom'
import LazyLoad from 'react-lazyload';
const Uniform = () => {
  return (
    <>
    <section className="page-header">
        <div className="page-header-bg"></div>
        <div className="page-header-shape-1"><LazyLoad><img src="https://webapi.entab.info/api/image/LCSD/public/Images/page-header-shape-1.png" alt="Loreto Convent School, Delhi Cantt"/></LazyLoad></div>
        <div className="container">
        <div className="page-header__inner">
            <ul className="thm-breadcrumb list-unstyled ml-0">
                <li><Link to="/"> Home</Link></li>
                <li> Prospectus </li>
                <li> School Uniform  </li>
            </ul>
            <h2>School Uniform </h2>
        </div>
        </div>
    </section>
    <section className="innerbody">
   <div className="container">
      <div className="row">
         <div className="col-md-12">
            <p><b>All pupils are expected to come to school in a clean and proper uniform with clean socks and polished shoes. If the hair is more than shoulder length, it must be tied into a neat pony tail and longer hair in a plait. Hair band must be used for short hair.</b></p>
         </div>
      </div>
      <div className="row">
         <div className="col-md-12">
            <h1 className="head1">SUMMERS</h1>
         </div>
         <div className="col-md-6">
            <h3 className="head3"><b>Prep - Class X</b></h3>
            <ul className="lilist">
               <li>White bloomers (Prep - Class IV)</li>
               <li>Candy striped dress according to school pattern (knee- length)</li>
               <li>White knee length socks</li>
               <li>Black school shoes with buckle</li>
               <li>White hairband / rubber band, Black hair clips</li>
            </ul>
         </div>
         <div className="col-md-6">
            <h3 className="head3"><b>Class XI-XII</b></h3>
            <ul className="lilist">
               <li>Candy striped kameez (knee length with sleeves below elbow)</li>
               <li>White trousers</li>
               <li>White socks</li>
               <li>Black school shoes with buckle</li>
               <li>White hair band/ rubber band, Black hair clips</li>
            </ul>
         </div>
         <div className="col-md-6">
            <h3 className="head3"><b>PT UNIFORM (Compulsory on PE Days)</b></h3>
            <ul className="lilist">
               <li>House T shirt with school logo</li>
               <li>White shorts (for classes Prep to V)</li>
               <li>White skirts with white shorts (for classes VI to XII) </li>
               <li>White hairband / rubber band, Black hair clips</li>
               <li>White sports shoes with Velcro and white socks (only for Prep and class I)</li>
               <li>White Gola shoes with white socks (for classes II to XII)</li>
            </ul>
         </div>
      </div>
      <div className="row">
         <div className="col-md-12">
            <h1 className="head1">WINTERS</h1>
         </div>
         <div className="col-md-6">
            <h3 className="head3"><b>Class Prep-X</b></h3>
            <ul className="lilist">
               <li>Tartan pinafore with school bow tie (Prep-Class IV) </li>
               <li>Tartan skirt (knee length) with School neck tie (V-X)</li>
               <li>White full sleeved shirt</li>
               <li>Maroon cardigan</li>
               <li>Maroon blazer with school crest on the pocket</li>
               <li>Green stockings</li>
               <li>Black school shoes</li>
               <li>Green hairband/rubberband</li>
               <li>Black hair clips</li>
               <li>Maroon wollen cap</li>
               <li><b>Wearing school blazer is compulsory during winters.</b></li>
            </ul>
         </div>
         <div className="col-md-6">
            <h3 className="head3"><b>Class XI-XII</b></h3>
            <ul className="lilist">
               <li>Maroon full-sleeved kameez</li>
               <li>Green trousers</li>
               <li>Maroon cardigan and maroon woollen muffler</li>
               <li>Maroon blazer with school crest on the pocket</li>
               <li>Green socks</li>
               <li>Black shoes</li>
               <li>Green hair band/ rubber band</li>
               <li>Black hair clips</li>
               <li><b>Wearing school blazer is compulsory during winters. </b></li>
            </ul>
         </div>
      </div>
      <div className="row">
         <div className="col-md-6">
            <h3 className="head3"><b>PT UNIFORM (ON COMPULSORY P.E. DAYS)</b></h3>
            <ul className="lilist">
               <li>House T-shirt with school logo House Track suit (Prep to XII)</li>
               <li>White Gola shoes and knee-length green socks</li>
               <li>Green hairband/rubber band</li>
               <li>Black hair clips</li>
            </ul>
         </div>
      </div>
   </div>
</section>
   </>
  )
}

export default Uniform