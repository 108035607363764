import axios from "axios"
import { API_URL, getHeader } from "../config"

export const getHighlight = async() => {
    const { data } = await axios.get(API_URL + `/api/highlight/LCSD`);
    console.log(data)
    return data;
} 
export const getBirthdays = async() => {    
    const { data } = await axios.get(API_URL + `/api/birthdays/LCSD`);
    return data;
} 
export const getNews = async() => {
    console.log("first")
    const { data } = await axios.get(API_URL + `/api/news/LCSD`);
    console.log("news:",data)
    return data;
} 
export const getNewsInner = async() => {
    console.log("first")
    const { data } = await axios.get(API_URL + `/api/news/all/LCSD`);
    console.log("news:",data)
    return data;
} 

export const getEvents = async() => {
    const { data } = await axios.get(API_URL + `/api/events/LCSD`);
    console.log(data)
    return data;
} 

export const getEventsinner = async() => {
    const { data } = await axios.get(API_URL + `/api/events/All/LCSD`);
    console.log(data)
    return data;
} 

export const getGallery = async() => {
    const { data } = await axios.get(API_URL + `/api/gallery/LCSD`);
    return data;
} 

export const getGalleryInner = async() => {
    const { data } = await axios.get(API_URL + `/api/gallery/All/LCSD`);
    return data;
} 

export const getSubGallery = async(id) => {
    console.log(id)
    const { data } = await axios.get(API_URL + `/api/subGallery?id=${id}`);
    console.log(data)
    return data;
} 

export const getNotification = async() => {
    const { data } = await axios.get(API_URL + `/api/notification/LCSD`,getHeader());
    return data;
} 

export const getTopper = async() => {
    const { data } = await axios.get(API_URL + `/api/topper/LCSD`,getHeader());
    return data;
} 

export const getOnlineRegistration = async() => {
    const { data } = await axios.get(API_URL + `/api/onlineRegistration/LCSD`,getHeader());
    console.log("online registration Data", data)
    return data;
} 

export const getSliders = async() => {
    const { data } = await axios.get(API_URL + `/api/slider/LCSD`,getHeader());
    return data;
} 

export const getPrincipalMessage = async() => {
    const { data } = await axios.get(API_URL + `/api/principalMessage/LCSD`, getHeader());
   
    return data;
} 

export const getMedia = async() => {
    const { data } = await axios.get(API_URL + `/api/media/LCSD`, getHeader());
    console.log(data);
    return data;
} 
export const getVideoGallery = async() => {
    const { data } = await axios.get(API_URL + `/api/video/all/LCSD`, getHeader());
    return data;
} 
export const getCircular = async() => {
    const { data } = await axios.get(API_URL + `/api/circular/all/LCSD`, getHeader());
    console.log(data);
    return data;
} 
export const getReading = async() => {
    const { data } = await axios.get(API_URL + `/api/reading/all/LCSD`, getHeader());
    console.log(data);
    return data;
} 
export const getFaculty = async() => {
    const { data } = await axios.get(API_URL + `/api/faculty/all/LCSD`, getHeader());
    console.log(data);
    return data;
} 

export const academicCalender = async() => {
    const { data } = await axios.get(API_URL + `/api/academicCalender/all/LCSD`, getHeader());
    console.log("academic calender", data)
    return data;
}
export const postTransferCertificate = async(name,admission) => {
    console.log(name,admission)
    const { data } = await axios.post(API_URL + `/api/tc/LCSD`,{name,admission},getHeader());
    return data;
}