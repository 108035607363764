import React from 'react'
import { Link } from 'react-router-dom'
import LazyLoad from 'react-lazyload';
const Policy = () => {
  return (
    <>
    <section className="page-header">
        <div className="page-header-bg"></div>
        <div className="page-header-shape-1"><LazyLoad><img src="https://webapi.entab.info/api/image/LCSD/public/Images/page-header-shape-1.png" alt="Loreto Convent School, Delhi Cantt"/></LazyLoad></div>
        <div className="container">
        <div className="page-header__inner">
            <ul className="thm-breadcrumb list-unstyled ml-0">
                <li><Link to="/"> Home</Link></li>
                <li> Prospectus </li>
                <li> Policy  </li>
            </ul>
            <h2>Policy </h2>
        </div>
        </div>
    </section>
    <section className="innerbody">
<div className="container">
<div className="row">
<div className="col-md-12"><p>Our Policy follows naturally from our philosophy. It provides the necessary guidance to enable us to make daily decisions designed to promote those values by which we live. It may have to be modified from time to time to meet changing situations.

</p></div>
</div>
</div>
</section>
   </>
  )
}

export default Policy