import React, { useState, useEffect } from 'react'; 

function ScrollTopBtn() {
  const [showButton, setShowButton] = useState(false);

  useEffect(() => {
    window.onscroll = () => {
      if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
        setShowButton(true);
      } else {
        setShowButton(false);
      }
    };

    return () => {
      window.onscroll = null;
    };
  }, []);

  const scrollToTop = () => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  };

  return (
    <button id="myBtn" onClick={scrollToTop} style={{ display: showButton ? 'block' : 'none' }} title="Go to top">
      Top
    </button>
  );
}

export default ScrollTopBtn;
